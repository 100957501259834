import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Section from 'components/common/Section';
import React from 'react';
import Form from './Form';

const ContactUs = () => {
  return (
    <Section id="contact-us" className="pb-[48px] pt-[72px] sm:py-20 xl:py-[96px]">
      <Container>
        <Grid>
          <div className="col-span-full flex flex-col xl:col-span-10 xl:col-start-2">
            <h2 className="mb-[15px] text-center text-head-four font-medium text-black-100 sm:mb-4 sm:text-head-two">Contact Us</h2>
            <p className="mb-10 text-center text-base text-neutral-60 sm:mb-16 sm:text-large xl:mb-[72px]">Any questions? Just write us a message!</p>
            <Form />
          </div>
        </Grid>
      </Container>
    </Section>
  );
};

export default ContactUs;
