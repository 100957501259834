import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Label from 'components/common/Label';
import Section from 'components/common/Section';
import React from 'react';
import Slider from './Testimonial/Slider';

const Testimonial = () => {
  return (
    <Section id="testimoni-krystal" className="pt-12 sm:pt-20  xl:pt-[120px]">
      <Container>
        <Grid>
          <div className="col-span-full flex flex-col items-center sm:col-span-6 sm:col-start-4 xl:col-span-full xl:col-start-1">
            <h2
              data-aos="fade-down"
              data-aos-delay="500"
              className="mb-[54px] hidden max-w-[354px] text-center text-head-three font-medium text-black-100 sm:mb-16 sm:block xl:max-w-none xl:text-head-two">
              See what others have to say{' '}
            </h2>
            <h2 data-aos="fade-down" data-aos-delay="700" className="mb-12 block text-center text-head-four font-medium text-black-100 sm:hidden">
              Don&apos;t just take our word for it
            </h2>
          </div>
          <Slider />
        </Grid>
      </Container>
    </Section>
  );
};

export default Testimonial;
