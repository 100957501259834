import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Section from 'components/common/Section';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
// image
import { ReactComponent as JobIcon } from 'assets/jobs/building.svg';
import { ReactComponent as SalaryIcon } from 'assets/jobs/bank.svg';
import { ReactComponent as PlaceIcon } from 'assets/jobs/marker.svg';
import { ReactComponent as FacebookIcon } from 'assets/job-details/facebook.svg';
import { ReactComponent as LinkedInIcon } from 'assets/job-details/linkedin.svg';
import { ReactComponent as KebabIcon } from 'assets/job-details/kebab.svg';
import { ReactComponent as TwitterIcon } from 'assets/job-details/twitter.svg';

import moment from 'moment';
import Button from 'components/common/Button';
import ApplyJobModal from './ModalApplyJob';
const Hero = ({ data }) => {
  // console.log('data job detail', data);
  const childRef = useRef(null);
  const jobId = data?.id;
  console.log('data', data);
  return (
    <Section id="hero-job" className="bg-brand-other-surface pb-10 pt-[144px] sm:pb-20 sm:pt-[176px] xl:pb-[96px] xl:pt-[192px]">
      <Container>
        <Grid>
          <div className="col-span-full sm:col-span-3 xl:col-span-2">
            <Link to="/jobs" className="mb-10 flex gap-x-3">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.6663 10H3.33301M3.33301 10L8.33301 15M3.33301 10L8.33301 5"
                  stroke="#121212"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-small font-medium text-neutral-70">Back to job search</p>
            </Link>
          </div>
          <div className="col-span-full mb-10 flex flex-col sm:flex-row sm:justify-between">
            <div className="mb-6 flex flex-col sm:mb-0">
              <h1 className="mb-4 text-head-four font-medium text-black-100 sm:text-head-three">{data.title}</h1>
              <div className="flex w-full flex-wrap gap-4">
                <div className="flex items-center gap-x-1">
                  <JobIcon className="h-5 w-5" />
                  <p className="text-base text-neutral-70 xl:text-base">{data.category?.name ? data.category.name : 'Unknown'} </p>
                </div>
                <div className="flex items-center gap-x-1">
                  <SalaryIcon className="h-5 w-5" />
                  <p className="text-base text-neutral-70 xl:text-base">{data.salary || 'Unknown'}</p>
                </div>
                <div className="flex items-center gap-x-1">
                  <PlaceIcon className="h-5 w-5" />
                  <p className="text-base text-neutral-70 xl:text-base">{data.city ? data.city : 'Unknown'}</p>
                </div>
              </div>
            </div>
            <p className=" text-small text-neutral-50 xl:text-base">{moment(data?.published_at).format('MMM DD, YYYY')}</p>
          </div>
          <div className="col-span-full flex flex-col items-center justify-between sm:flex-row">
            <Button isPrimary className="w-full sm:max-w-[200px] " onClick={() => childRef.current.openModal()}>
              Apply Now
            </Button>
            <div className="mt-10 flex w-full sm:hidden">
              <div className="flex gap-x-3 ">
                <a target="_blank" href="https://www.facebook.com/IndustryTalentAU" rel="noreferrer">
                  <FacebookIcon className="h-6 w-6 text-brand-primary-main" />
                </a>

                <a target="_blank" href="https://www.linkedin.com/company/industry-talent-au/" rel="noreferrer">
                  <LinkedInIcon className="h-6 w-6 text-brand-primary-main" />
                </a>
              </div>
            </div>
            <div className="hidden gap-x-3 sm:flex">
              <a target="_blank" href="https://www.facebook.com/IndustryTalentAU" rel="noreferrer">
                <FacebookIcon className="h-6 w-6 text-brand-primary-main" />
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/industry-talent-au/" rel="noreferrer">
                <LinkedInIcon className="h-6 w-6 text-brand-primary-main" />
              </a>
            </div>
          </div>
        </Grid>
      </Container>
      <ApplyJobModal jobId={jobId} childRef={childRef} />
    </Section>
  );
};

export default Hero;
