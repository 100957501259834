/* eslint-disable react/no-unescaped-entities */
import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Label from 'components/common/Label';
import Section from 'components/common/Section';
import React from 'react';
import KrystalImage from 'assets/about/krystal.png';
import KrystalImageTablet from 'assets/about/krystal-tablet.png';

const Krystal = () => {
  return (
    <Section id="krystal" className="bg-white pb-12 pt-12 sm:pb-20 sm:pt-20 xl:pb-[128px] xl:pt-[140px]">
      <Container>
        <Grid>
          <div className="col-span-full flex flex-col items-center xl:hidden">
            <h2 data-aos="fade-down" data-aos-delay="500" className="mb-6 text-head-four font-medium text-black-100 sm:mb-12 sm:text-head-three">
              About Krystal{' '}
            </h2>
            {/* <p data-aos="fade-down" data-aos-delay="700" className="mb-10 block text-center text-base tracking-wide text-neutral-60 sm:hidden">
              A remote-first team with the HQ in Sydney, with over 74 individuals located all around the world. We are a mission driven team.
            </p> */}
          </div>
          <div className="col-span-full sm:col-span-4 xl:col-span-5">
            <div className="relative mb-[60px] ml-3 h-[323px] w-[262px] sm:mb-0 sm:ml-0 sm:h-[323px] sm:w-[263px] xl:h-[504px] xl:w-[409px]">
              <img
                data-aos="fade-down-left"
                data-aos-delay="900"
                src={KrystalImage}
                alt="Krystal"
                className="-[323px] relative z-[2] hidden w-auto object-cover sm:h-[323px] xl:block xl:h-[504px]"
              />
              <img
                data-aos="fade-down-left"
                data-aos-delay="900"
                src={KrystalImageTablet}
                alt="Krystal"
                className="-[323px] relative z-[2] block w-auto object-cover sm:h-[323px] xl:hidden xl:h-[504px]"
              />
              <div
                data-aos="fade-up-left"
                data-aos-delay="1100"
                className="absolute -bottom-5 -right-5 h-[323px] w-[262px] bg-brand-other-surface sm:h-[323px] sm:w-[263px] xl:-bottom-[32px] xl:-right-[32px] xl:h-[504px] xl:w-[409px]"></div>
            </div>
          </div>
          <div className="col-span-full flex flex-col items-start sm:col-span-8 xl:col-span-7">
            <h2 data-aos="fade-down" data-aos-delay="500" className="mb-6 mt-8 hidden text-head-two font-medium text-black-100 xl:block">
              About Krystal{' '}
            </h2>
            <p data-aos="fade-down" data-aos-delay="700" className="mb-6 text-base text-neutral-60 sm:mt-2 xl:mt-0 xl:text-large">
              Driven by a passion for accounting and property, Krystal's journey in the industry began back in 2014. Her genuine love for real estate
              and property is deeply ingrained in her life, shaping her professional path. Through her years in the field, Krystal has developed an
              acute understanding of her clients' needs, drawing from her own personal experiences. Alongside her practical knowledge, her completion
              of a Bachelor of Commerce has equipped her with a well-rounded expertise, allowing her to skillfully match the perfect solutions to her
              clients' requirements.{' '}
            </p>
            <p data-aos="fade-down" data-aos-delay="900" className="mb-8 text-base text-neutral-60 sm:mb-12 xl:text-large">
              As a true industry enthusiast, Krystal's journey led her to recognise the increasing demand for high-quality and personalised
              recruitment services designed exclusively for her clients.
            </p>
            <h6 data-aos="fade-down" data-aos-delay="1100" className="mb-3 text-large font-medium text-black-100 xl:text-head-six">
              Krystal Smithers
            </h6>
            <div
              data-aos="fade-right"
              data-aos-delay="1200"
              className="flex w-full flex-col items-start sm:flex-row sm:items-center sm:justify-between">
              <p className="mb-6 text-small uppercase text-neutral-70 sm:mb-0 sm:text-base">Director</p>
              <div className="flex gap-x-5">
                <a
                  href="https://www.linkedin.com/in/krystal-smithers-779242123/"
                  target="_blank"
                  rel="noreferrer"
                  className="h-6 w-6 text-neutral-50 transition-all hover:text-brand-other-main">
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1 2.838C1 2.35053 1.19365 1.88303 1.53834 1.53834C1.88303 1.19365 2.35053 1 2.838 1H21.16C21.4016 0.999608 21.6409 1.04687 21.8641 1.13907C22.0874 1.23127 22.2903 1.36661 22.4612 1.53734C22.6322 1.70807 22.7677 1.91083 22.8602 2.13401C22.9526 2.3572 23.0001 2.59643 23 2.838V21.16C23.0003 21.4016 22.9529 21.6409 22.8606 21.8642C22.7683 22.0875 22.6328 22.2904 22.462 22.4613C22.2912 22.6322 22.0884 22.7678 21.8651 22.8602C21.6419 22.9526 21.4026 23.0001 21.161 23H2.838C2.59655 23 2.35746 22.9524 2.1344 22.86C1.91134 22.7676 1.70867 22.6321 1.53798 22.4613C1.3673 22.2905 1.23193 22.0878 1.13962 21.8647C1.04731 21.6416 0.999869 21.4025 1 21.161V2.838ZM9.708 9.388H12.687V10.884C13.117 10.024 14.217 9.25 15.87 9.25C19.039 9.25 19.79 10.963 19.79 14.106V19.928H16.583V14.822C16.583 13.032 16.153 12.022 15.061 12.022C13.546 12.022 12.916 13.111 12.916 14.822V19.928H9.708V9.388ZM4.208 19.791H7.416V9.25H4.208V19.79V19.791ZM7.875 5.812C7.88105 6.08668 7.83217 6.35979 7.73124 6.61532C7.63031 6.87084 7.47935 7.10364 7.28723 7.30003C7.09511 7.49643 6.8657 7.65248 6.61246 7.75901C6.35921 7.86554 6.08724 7.92042 5.8125 7.92042C5.53776 7.92042 5.26579 7.86554 5.01255 7.75901C4.7593 7.65248 4.52989 7.49643 4.33777 7.30003C4.14565 7.10364 3.99469 6.87084 3.89376 6.61532C3.79283 6.35979 3.74395 6.08668 3.75 5.812C3.76187 5.27286 3.98439 4.75979 4.36989 4.38269C4.75539 4.00558 5.27322 3.79442 5.8125 3.79442C6.35178 3.79442 6.86961 4.00558 7.25512 4.38269C7.64062 4.75979 7.86313 5.27286 7.875 5.812Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/industrytalentau/"
                  target="_blank"
                  rel="noreferrer"
                  className="h-6 w-6 text-neutral-50 transition-all hover:text-brand-other-main">
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.0001 8.87579C10.2798 8.87579 8.87589 10.2797 8.87589 12C8.87589 13.7203 10.2798 15.1242 12.0001 15.1242C13.7204 15.1242 15.1243 13.7203 15.1243 12C15.1243 10.2797 13.7204 8.87579 12.0001 8.87579ZM21.3704 12C21.3704 10.7063 21.3821 9.42423 21.3095 8.13283C21.2368 6.63283 20.8946 5.30158 19.7978 4.2047C18.6985 3.10548 17.3696 2.76564 15.8696 2.69298C14.5759 2.62033 13.2939 2.63204 12.0025 2.63204C10.7087 2.63204 9.42667 2.62033 8.13527 2.69298C6.63527 2.76564 5.30402 3.10783 4.20714 4.2047C3.10792 5.30392 2.76808 6.63283 2.69542 8.13283C2.62277 9.42658 2.63449 10.7086 2.63449 12C2.63449 13.2914 2.62277 14.5758 2.69542 15.8672C2.76808 17.3672 3.11027 18.6985 4.20714 19.7953C5.30636 20.8945 6.63527 21.2344 8.13527 21.307C9.42902 21.3797 10.711 21.368 12.0025 21.368C13.2962 21.368 14.5782 21.3797 15.8696 21.307C17.3696 21.2344 18.7009 20.8922 19.7978 19.7953C20.897 18.6961 21.2368 17.3672 21.3095 15.8672C21.3845 14.5758 21.3704 13.2938 21.3704 12ZM12.0001 16.807C9.33995 16.807 7.19308 14.6602 7.19308 12C7.19308 9.33986 9.33995 7.19298 12.0001 7.19298C14.6603 7.19298 16.8071 9.33986 16.8071 12C16.8071 14.6602 14.6603 16.807 12.0001 16.807ZM17.004 8.11876C16.3829 8.11876 15.8814 7.6172 15.8814 6.99611C15.8814 6.37501 16.3829 5.87345 17.004 5.87345C17.6251 5.87345 18.1267 6.37501 18.1267 6.99611C18.1269 7.14359 18.0979 7.28966 18.0416 7.42595C17.9852 7.56224 17.9026 7.68607 17.7983 7.79036C17.694 7.89464 17.5701 7.97733 17.4339 8.03368C17.2976 8.09004 17.1515 8.11895 17.004 8.11876Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </Grid>
      </Container>
    </Section>
  );
};

export default Krystal;
