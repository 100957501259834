import Container from 'components/common/Container';
import Label from 'components/common/Label';
import Section from 'components/common/Section';
import React from 'react';
import { Link } from 'react-router-dom';
import SliderJobs from './SliderJobs';

const Jobs = () => {
  return (
    <Section id="jobs" className="">
      <Container className="relative py-[48px] sm:pb-[64px] sm:pt-[64px] xl:pt-[96px]">
        <div className="grid grid-cols-4 gap-x-8 sm:grid-cols-12">
          <div className="col-span-full mb-[40px] sm:col-span-6 sm:col-start-4 sm:mb-[64px]">
            <div className="mx-auto flex w-full max-w-[410px] flex-col items-center">
              <h2
                data-aos="fade-up"
                data-aos-delay="500"
                className="mb-6 text-head-four font-medium text-black-100 sm:text-head-three xl:text-head-two">
                Current Jobs
              </h2>
              <p data-aos="fade-up" data-aos-delay="700" className="text-center text-large text-black-60">
                Most of our jobs are not advertised. Register your interest to access unseen opportunities.
              </p>
            </div>
          </div>
        </div>
        <SliderJobs />
        <div className=" flex items-center">
          <Link
            to="/jobs"
            data-aos="fade-up"
            data-aos-delay="1100"
            className=" mx-auto flex items-center border-none bg-transparent p-0 font-ranade text-medium font-medium text-black-100">
            View More
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className="ml-[10px]" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 15L15 5M15 5H8.33333M15 5V11.6667" stroke="#121212" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        </div>
      </Container>
    </Section>
  );
};

export default Jobs;
