const ListStep = [
  {
    time: 'Today',
    title: 'Submit your vacancy '
  },
  {
    time: '48 Hours',
    title: 'Phone consult with recruiter '
  },
  {
    time: 'Week one',
    title: 'Candidates sourced and screened'
  },
  {
    time: 'Week two',
    title: 'Interviews conducted by you '
  },
  {
    time: 'Week three',
    title: 'Offer Presented and Negotiated by us  '
  },
  {
    time: 'Week four',
    title: 'Your candidate joins your team!'
  }
];

export default ListStep;
