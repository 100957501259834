import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import React, { useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Keyboard, Pagination } from 'swiper';
import useSwiperRef from 'utils/useSwiperRef';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
const SliderHiring = () => {
  // const [countries, setCountries] = useState([]);
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();

  return (
    <div className="relative">
      <Swiper
        id="hiring-slider"
        className="w-full overflow-x-hidden"
        // style={{
        //   overflow: 'visible'
        // }}
        pagination={{
          el: '.hiring-pagination',
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"></span>';
          }
        }}
        spaceBetween={32}
        slidesPerView={1}
        centeredSlides={true}
        onSwiper={(swiper) => console.log(swiper)}
        navigation={{ prevEl, nextEl, clickable: true }}
        keyboard={true}
        loop={true}
        speed={1000}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true
        }}
        modules={[Autoplay, Navigation, Keyboard, Pagination]}>
        <SwiperSlide>
          <div className="flex w-full flex-col gap-y-2">
            <div className="flex bg-brand-other-surface px-4 py-2">
              <CheckCircleIcon className="mr-[12px] h-[20px] w-auto text-brand-other-main" />
              <p className="text-small font-medium text-black-90">Recruitment Experts</p>
            </div>
            <div className="flex bg-brand-other-surface px-4 py-2">
              <CheckCircleIcon className="mr-[12px] h-[20px] w-auto text-brand-other-main" />
              <p className="text-small font-medium text-black-90">Qualified Candidates</p>
            </div>
            <div className="flex bg-brand-other-surface px-4 py-2">
              <CheckCircleIcon className="mr-[12px] h-[20px] w-auto text-brand-other-main" />
              <p className="text-small font-medium text-black-90">Sourcing</p>
            </div>
            <div className="flex bg-brand-other-surface px-4 py-2">
              <CheckCircleIcon className="mr-[12px] h-[20px] w-auto text-brand-other-main" />
              <p className="text-small font-medium text-black-90">Interviewing and screening </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex w-full flex-col gap-y-2">
            {' '}
            <div className="flex bg-brand-other-surface px-4 py-2">
              <CheckCircleIcon className="mr-[12px] h-[20px] w-auto text-brand-other-main" />
              <p className="text-small font-medium text-black-90">Communication</p>
            </div>
            <div className="flex bg-brand-other-surface px-4 py-2">
              <CheckCircleIcon className="mr-[12px] h-[20px] w-auto text-brand-other-main" />
              <p className="text-small font-medium text-black-90">Negotiation</p>
            </div>
            <div className="flex bg-brand-other-surface px-4 py-2">
              <CheckCircleIcon className="mr-[12px] h-[20px] w-auto text-brand-other-main" />
              <p className="text-small font-medium text-black-90">Onboarding</p>
            </div>{' '}
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="hiring-pagination mt-4 flex justify-center"></div>
    </div>
  );
};

export default SliderHiring;
