import clsx from 'clsx';
import React, { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const Linemobile = ({ index, delayTime }) => {
  const indexTrue = [1, 2, 3, 4];
  gsap.registerPlugin(ScrollTrigger);
  const pathRef = React.useRef(null);
  useEffect(() => {
    gsap.fromTo(
      pathRef.current,
      {
        height: 0
      },
      {
        height: '100%',
        duration: 1.5,
        delay: delayTime,
        scrollTrigger: {
          trigger: '#line-black'
        }
      }
    );
  }, []);
  return (
    <div className={clsx('absolute  left-[8px] top-0', index === 0 && 'h-[78px]', indexTrue.includes(index) && 'h-[110px]')}>
      <hr className={clsx('relative z-[1] h-full border-r border-black-30')} />
      <hr id="line-black" ref={pathRef} className={clsx('absolute top-0 z-[2] border-r border-brand-primary-main')} />
    </div>
  );
};

export default Linemobile;
