import React from 'react';
import PropTypes from 'prop-types';

const Previous = ({ onClick }) => {
  return (
    <button className="flex items-center gap-x-2 text-black-70" onClick={onClick}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.8337 10.0001H4.16699M4.16699 10.0001L10.0003 15.8334M4.16699 10.0001L10.0003 4.16675"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <p className="hidden text-base  font-medium lg:block">Previous</p>
    </button>
  );
};

Previous.propTypes = {
  onClick: PropTypes.func
};

export default Previous;
