import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card, { CardHeader } from './Components/Card';

const JobLocation = ({ jobs, setFilterJobLocation, filterJobLocation }) => {
  const [locationType, setLocationType] = useState([]);

  useEffect(() => {
    const uniqueCity = [...new Set(jobs.map((item) => `${item?.city}`))];
    const listJobCity = uniqueCity.map((uniqueItem) => {
      const filterCity = jobs.filter(function (item) {
        if (`${item?.city}` === uniqueItem) {
          return true;
        } else {
          return false;
        }
      });

      const count = filterCity.length;
      return {
        name: uniqueItem === 'null' ? 'Other' : uniqueItem,
        count
      };
    });

    const listJobCitySort = listJobCity.sort((a, b) => b.count - a.count);
    const listJobCityPrimary = listJobCitySort.slice(0, 9);

    const amountOther = listJobCitySort.slice(9).reduce((accumulator, object) => {
      return accumulator + object.count;
    }, 0);
    listJobCityPrimary.map((item) => {
      if (item.name === 'Other') {
        item.count += amountOther;
      }
    });
    listJobCityPrimary.push(listJobCityPrimary.shift());
    // listJobCityPrimary.push({
    //   name: "Other",
    //   count: amountOther
    // })

    setLocationType(listJobCityPrimary);
  }, [jobs]);

  const handleCheckbox = (e) => {
    setFilterJobLocation((state) => {
      let temp = [...state];
      if (temp.includes(e.target.value)) {
        temp = temp.filter((item) => item !== e.target.value);
      } else {
        temp = [...state, e.target.value];
      }
      return [...temp];
    });
  };

  return (
    <Card>
      <div className="border-b border-b-neutral-30 pb-4">
        <CardHeader>Job Locations</CardHeader>
      </div>

      <div className="mt-4 flex flex-col gap-y-1">
        {locationType?.map((item, index) => {
          return (
            <>
              <Checkbox
                name={item?.name}
                count={item?.count}
                id={`${item?.name}-${index}`}
                index={index}
                onClick={handleCheckbox}
                value={item?.name}
                checked={filterJobLocation?.includes(item?.name)}
              />
            </>
          );
        })}
      </div>
    </Card>
  );
};

const Checkbox = ({ name, count, id, index, onClick, checked, value }) => {
  // const [checked, setChecked] = useState(false);
  return (
    <div key={index} className="flex items-center justify-between py-1 sm:py-3">
      <div className="flex items-center gap-x-4">
        <input
          id={id}
          type="checkbox"
          value={value}
          className="border-nudes-600 hover:cursor-pointer focus:ring-0"
          onClick={onClick}
          checked={checked}
        />
        <label htmlFor={id} className="text-medium text-black-100 hover:cursor-pointer">
          {name}
        </label>
      </div>
      <p className="text-medium text-black-60">{count}</p>
    </div>
  );
};

JobLocation.propTypes = {
  setFilterJobLocation: PropTypes.func,
  jobs: PropTypes.array
};

export default JobLocation;
