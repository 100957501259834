import Button from 'components/common/Button';
import InputPrimary from 'components/common/InputPrimary';
import React from 'react';
import queryStore from 'store/store';

const FormSearch = () => {
  const [queryTitle, setQueryTitle, queryLocation, setQueryLocation] = queryStore((state) => [
    state.queryTitle,
    state.setQueryTitle,
    state.queryLocation,
    state.setQueryLocation
  ]);
  const handleSearch = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const keyword = formData.get('keyword');
    const location = formData.get('location');
    setQueryTitle(keyword);
    setQueryLocation(location);
  };
  return (
    <form
      onSubmit={handleSearch}
      data-aos="fade-up"
      data-aos-delay="700"
      className=" flex w-full max-w-[457px] flex-col gap-3 sm:mb-[48px] xl:mb-[40px] xl:max-w-[624px] xl:flex-row xl:gap-x-3">
      <div className="flex w-full flex-col gap-y-3 sm:flex-row sm:gap-x-3 sm:gap-y-0">
        <InputPrimary
          className={'h-[56px] w-full border-none  bg-neutral-10 px-[20px] py-4 text-base placeholder:text-neutral-50 sm:max-w-[240px] xl:h-auto'}
          name="keyword"
          placeholder={'Job title or keywords'}
        />
        <InputPrimary
          className={'h-[56px] w-full  border-none bg-neutral-10 px-[20px] py-4 text-base placeholder:text-neutral-50 sm:max-w-[240px] xl:h-auto'}
          name="location"
          placeholder={'Location'}
        />
      </div>
      <Button isPrimary={true} type="submit" className="w-full xl:max-w-[120px]">
        Search
      </Button>
    </form>
  );
};

export default FormSearch;
