import Container from 'components/common/Container';
import Section from 'components/common/Section';
import React, { useState, Fragment, useRef } from 'react';
import { ReactComponent as PhoneIcon } from 'assets/home/getInTouch/phone.svg';
import { ReactComponent as EmailIcon } from 'assets/home/getInTouch/mail.svg';
import InputPrimary from 'components/common/InputPrimary';
import Modal from './Modal';
import Button from 'components/common/Button';
import emailjs from '@emailjs/browser';

import toast, { Toaster } from 'react-hot-toast';

const GetInTouch = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [sector, setSector] = useState('');
  const [message, setMessage] = useState('');
  const formRef = useRef();

  // onsubmit form
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log('formRef', formRef.current);
    emailjs.sendForm('service_46djnbl', 'template_bsyv7u5', formRef.current, 'N0SI-LyAwmtVS2IjK').then(
      (result) => {
        console.log('result.text', result.text);
        setName('');
        setEmail('');
        setSector('');
        setMessage('');
        openModal();
      },
      (error) => {
        console.log(error.text);
        toast.error('Message failed to send.');
      }
    );
    console.log('list data', name, email, sector, message);
    // openModal();
  };
  const ListIndustries = [
    'Accounting',
    'Residential and Commercial Real Estate',
    'Body Corporate',
    'Finance and Investment',
    'Building and Construction',
    'Property Development',
    'Executive Search',
    'Other'
  ];
  return (
    <>
      <Toaster />
      <Section id="get-in-touch" className="w-full pb-12 sm:py-20 xl:py-[96px]">
        <Container>
          <div className="grid w-full grid-cols-4 gap-x-8 sm:grid-cols-12">
            <div className="col-span-full flex flex-col sm:col-span-6">
              <div className="flex w-full max-w-[327px] flex-col sm:max-w-[432px]">
                <h3 className="mb-4 text-head-four font-medium text-black-100 sm:text-head-three" data-aos="fade-left" data-aos-delay="300">
                  Get in touch with us today!
                </h3>
                <p data-aos="fade-left" data-aos-delay="500" className="mb-10 text-medium text-black-60 sm:mb-16">
                  Industry Talent would love to hear from you. Book in a coffee with one of our specialists today.{' '}
                </p>
              </div>
              <div className="mb-10 flex flex-col gap-[30px] sm:mb-0 sm:gap-8 xl:flex-row xl:gap-0">
                <div data-aos="fade-down" data-aos-delay="700" className="flex xl:mr-10">
                  <div className="mr-4 flex h-12 w-12 items-center justify-center rounded-full border border-black-20">
                    <PhoneIcon className="h-6 w-6 " />
                  </div>
                  <div className="flex flex-col justify-between">
                    <p className="text-xsmall text-black-60">Our Phone Number</p>
                    <p className="text-medium text-black-90">(+61)0480 096 705</p>
                  </div>
                </div>
                <div data-aos="fade-down" data-aos-delay="900" className="flex">
                  <div className="mr-4 flex h-12 w-12 items-center justify-center rounded-full border border-black-20">
                    <EmailIcon className="h-6 w-6 " />
                  </div>

                  <div className="flex flex-col justify-between">
                    <p className="text-xsmall text-black-60">Our Email Address</p>
                    <p className="text-medium text-black-90">krystal@industrytalent.com.au</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-full flex flex-col sm:col-span-6 xl:col-span-5">
              <form ref={formRef} onSubmit={onSubmit} className="flex w-full flex-col gap-6 sm:max-w-[420px] xl:max-w-[470px]">
                <div data-aos="fade-right" data-aos-delay="300" className="flex flex-col">
                  <label htmlFor="name" className="mb-2 text-small text-black-90">
                    Name
                  </label>
                  <InputPrimary
                    className="rounded text-small placeholder:text-black-50"
                    type="text"
                    name="from_name"
                    id="from_name"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your Name..."
                  />
                </div>
                <div data-aos="fade-right" data-aos-delay="500" className="flex flex-col">
                  <label htmlFor="name" className="mb-2 text-small text-black-90">
                    Email
                  </label>
                  <InputPrimary
                    className="rounded text-small placeholder:text-black-50"
                    type="email"
                    name="reply_to"
                    id="reply_to"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your Email..."
                  />
                </div>
                <div data-aos="fade-right" data-aos-delay="700" className="flex flex-col">
                  <label htmlFor="name" className="mb-2 text-small text-black-90">
                    Sector
                  </label>
                  <select
                    name="sector"
                    id="sector"
                    value={sector}
                    onChange={(e) => setSector(e.target.value)}
                    required
                    className="rounded border-black-30 px-4 py-[13px] text-small placeholder:text-black-50 invalid:border-black-30 invalid:text-black-50 focus:border-brand-primary-main focus:ring-0 disabled:text-black-50">
                    <option value="" disabled defaultValue={true} className="invalid:text-black-50 disabled:text-black-50">
                      Select Here
                    </option>
                    {ListIndustries.map((item, index) => (
                      <option className="text-brand-primary-main" key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div data-aos="fade-right" data-aos-delay="900" className="flex flex-col">
                  <label htmlFor="name" className="mb-2 text-small text-black-90">
                    How Can We Help?
                  </label>
                  <textarea
                    required
                    type="text"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    id="message"
                    className="h-[136px] resize-none  rounded border border-black-30 px-4 py-[14px] text-small text-brand-primary-main placeholder:text-black-50 focus:border-brand-primary-main focus:ring-0 sm:py-4"
                    placeholder="Tell me here..."
                  />
                </div>
                <Button data-aos="fade-right" data-aos-delay="1100" isPrimary isMedium type="submit">
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </Container>
      </Section>
      <Modal isOpen={isOpen} closeModal={closeModal} />
    </>
  );
};

export default GetInTouch;
