import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Section from 'components/common/Section';
import React from 'react';
import Access from './Access';
import Elevate from './Elevate';
import ScaleTeam from './ScaleTeam';

const Competitive = () => {
  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <Section id="competitive" className={' order-2 pb-[24px]  pt-[90px] sm:pb-[96px] sm:pt-[78px] xl:py-24'}>
      <Container>
        <Grid>
          <div className="col-span-full mb-8 flex flex-col items-center justify-center sm:col-span-10 sm:col-start-2 sm:mb-16 xl:col-span-8 xl:col-start-3">
            <div className="w-full max-w-[632px] text-center">
              <h2
                data-aos="fade-up"
                data-aos-delay="300"
                className="mb-6 text-head-four font-medium text-black-100 sm:text-head-three xl:text-head-two">
                Give Your Business the Competitive Advantage
              </h2>
              <p data-aos="fade-down" data-aos-delay="500" className="text-base text-black-60 sm:text-large">
                Get access to industry leading talent and push your business to new heights.
              </p>
            </div>
          </div>
          <div className="col-span-full mb-6 px-[22px] sm:col-span-4 sm:mb-0 sm:px-0" data-aos="flip-left" data-aos-delay="700">
            <ScaleTeam handleScroll={handleScroll} />
          </div>
          <div className="col-span-full mb-6 px-[22px] sm:col-span-4 sm:mb-0 sm:px-0" data-aos="flip-left" data-aos-delay="700">
            <Access handleScroll={handleScroll} />
          </div>
          <div className="col-span-full mb-6 px-[22px] sm:col-span-4 sm:mb-0 sm:px-0" data-aos="flip-left" data-aos-delay="700">
            <Elevate handleScroll={handleScroll} />
          </div>
        </Grid>
      </Container>
    </Section>
  );
};

export default Competitive;
