import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Section from 'components/common/Section';
import React from 'react';
import HeroBgDekstop from 'assets/contact/hero-bg.png';
import HeroBgTablet from 'assets/contact/hero-bg-tablet.png';
import HeroBgMobile from 'assets/contact/hero-bg-mobile.png';

const Hero = () => {
  return (
    <Section id="get-in-touch" className="relative z-[2] h-[644px] sm:h-[452px] xl:h-[540px]">
      <img src={HeroBgDekstop} className=" hidden h-full w-full object-cover xl:block" alt="Hero" />
      <img src={HeroBgTablet} className="hidden h-full w-full object-cover sm:block xl:hidden" alt="Hero" />
      <img src={HeroBgMobile} className="block h-full w-full object-cover sm:hidden" alt="Hero" />
      {/* <CircleImg className="absolute left-0 -bottom-[260px] z-[2] hidden sm:block xl:hidden" /> */}
      <div className="absolute bottom-0 left-0 right-0 top-0 bg-hero">
        <Container className="pt-[208px] sm:pb-[148px] sm:pt-[204px] xl:pb-[200px] xl:pt-[240px]">
          <Grid>
            <div className="col-span-full sm:col-span-10 sm:col-start-2">
              <div className="mx-auto flex w-full max-w-[900px] flex-col text-white">
                <h1 data-aos="fade-up" data-aos-delay="300" className="mb-6 text-center text-head-three font-medium sm:mb-4 sm:text-head-two">
                  Get in touch with us!
                </h1>
                <p data-aos="fade-up" data-aos-delay="500" className="text-center text-large">
                  If you need help or have a question, we’re here for you
                </p>
              </div>
            </div>
          </Grid>
        </Container>
      </div>
    </Section>
  );
};

export default Hero;
