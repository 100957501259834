import clsx from 'clsx';
import ListStepDekstop from 'constant/hiring/ListStepDekstop';
import React, { useEffect, useState } from 'react';

import Line from './Line';
import Circle from './Circle';
import Caption from './Caption';
import useResponsive from 'utils/useResponsive';

import ListStep from 'constant/hiring/ListStep';

const Stepper = () => {
  const { lg, xl } = useResponsive();
  const [content, setContent] = useState(ListStepDekstop);
  useEffect(() => {
    if (xl) {
      setContent(ListStepDekstop);
    } else if (lg) {
      setContent(ListStep);
    }
  }, [lg, xl]);

  return (
    <>
      <div className="mx-auto  hidden w-full max-w-[802px] justify-center sm:flex xl:max-w-[1033px]">
        <ol className="mb-[90px] flex items-center xl:mb-[100px]">
          {content?.map((item, index) => {
            return (
              <li
                className={clsx(
                  ' relative ',
                  index === 0 && 'pr-34 lg:pr-[122px] xl:pr-[188px]',
                  index === 1 && 'pr-34 lg:pr-[145px] xl:pr-[188px]',
                  index === 2 && 'pr-34 lg:pr-[146px] xl:pr-[188px]',
                  index === 3 && 'pr-34 lg:pr-[145px] xl:pr-[188px]',
                  index === 4 && 'pr-34 lg:pr-[147px]  xl:pr-[188px]',
                  index === 5 && 'pr-0 xl:pr-0'
                )}
                key={index}>
                <Caption index={index} delayTime={1.5 * index} title={item.title} time={item.time} />
                <div className="absolute inset-0 z-[1] flex items-center">
                  <div className="h-[1px] w-full bg-neutral-30"></div>
                </div>
                <Line delayTime={1.5 * index} />
                <Circle delayTime={1.5 * index} />
              </li>
            );
          })}
        </ol>
      </div>
    </>
  );
};

export default Stepper;
