import React from 'react';
import PropTypes from 'prop-types';

const Next = ({ onClick }) => {
  return (
    <button onClick={onClick} className="button-l-medium flex items-center gap-x-2 text-black-70">
      <p className="hidden lg:block">Next</p>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.16667 10.0003H15.8333M15.8333 10.0003L10 4.16699M15.8333 10.0003L10 15.8337"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

Next.propTypes = {
  onClick: PropTypes.func
};

export default Next;
