import React, { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const CaptionMobile = ({ delayTime, time, title }) => {
  gsap.registerPlugin(ScrollTrigger);
  const pathRef = React.useRef(null);

  useEffect(() => {
    gsap.fromTo(
      pathRef.current,
      {
        color: '#E0E0E0',
        duration: 0.5
      },
      {
        color: '#3D3D3D',
        duration: 0.5,
        delay: delayTime,
        ease: 'none',
        scrollTrigger: {
          trigger: '#caption'
        }
      }
    );
  }, []);
  return (
    <p ref={pathRef} id="caption" className="text-large font-medium ">
      {time} - {title}
    </p>
  );
};

export default CaptionMobile;
