import gsap from 'gsap';
import { useEffect } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const ScaleIconAnimation = (pathRef) => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.fromTo(
      pathRef.current,
      {
        left: '-25px',
        bottom: '-25px',
        opacity: 1
      },
      {
        left: '15px',
        bottom: '15px',
        opacity: 1,
        delay: 1,
        duration: 1.3,
        ease: 'Power3.ease',
        scrollTrigger: {
          trigger: '#scale-icon'
        }
      }
    );
  }, []);
};

export default ScaleIconAnimation;
