import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from 'components/common/Button';
import { ReactComponent as CheckIcon } from 'assets/home/getInTouch/check.svg';
const Modal = ({ isOpen, closeModal }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black-100 bg-opacity-25 backdrop-blur-[9.5px]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto sm:inset-x-0  xl:inset-0">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="h-[264px] w-full max-w-[327px] transform overflow-hidden rounded-xl bg-white px-7 pb-6 pt-10 text-left align-middle transition-all sm:max-w-[366px]">
                <div className="flex flex-col items-center">
                  <CheckIcon className="mb-4 h-10 w-10" />
                  <h6 className="mb-2 text-large font-medium text-base-black-100">Enquiry Submitted</h6>
                  <p className="mb-8 text-small tracking-wide text-base-black-60">Industry Talent will contact you soon!</p>
                </div>

                <Button isPrimary onClick={closeModal} className="w-full text-medium">
                  Done
                </Button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
