import ScaleIconAnimation from 'animation/hiring/ScaleIconAnimation';
import clsx from 'clsx';

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const ScaleTeam = ({ handleScroll }) => {
  const [linkIsHover, setLinkIsHover] = useState('');
  const isHover = linkIsHover === 'scale';
  const boxScale = useRef();

  ScaleIconAnimation(boxScale);
  // useLayoutEffect(() => {
  //   FadeIn(boxScale.current, {
  //     x: 100,
  //     ease: 'Power3.easeInOut',
  //     scrollTrigger: {
  //       trigger: '#scale-icon'
  //     }
  //   });
  // }, []);

  return (
    <Link to="/about-us" onMouseOver={() => setLinkIsHover('scale')} onMouseLeave={() => setLinkIsHover('')}>
      <div className="relative h-[303px] sm:h-[359px] xl:h-[320px]">
        {/* Box Shadow */}
        <div
          className={clsx(
            'absolute left-4 top-6 bg-brand-other-main bg-opacity-[0.26] blur-[80px]',
            'transition-all duration-700',
            isHover ? 'h-[272px] w-[236px]' : 'h-[0px] w-[0px]'
          )}></div>

        {/* Background */}
        <div className={clsx('absolute left-0 top-0 h-full w-full overflow-hidden border border-black-30 bg-white')}>
          <div
            className={clsx(
              'relative h-[200%] w-[200%] bg-brand-other-main',
              'left-[-50%] origin-center transform rounded-[50%] duration-[350ms]',
              'transition-all ease-out',
              isHover ? 'translate-y-[-7.5%] scale-100' : 'translate-y-0 scale-0'
            )}></div>
        </div>

        {/* Content */}
        <div
          className={clsx(
            'relative flex min-h-[303px] flex-col justify-between px-6 py-6 sm:min-h-[359px] xl:min-h-[320px]',
            'transform duration-500',
            isHover ? 'translate-y-[-2.5%]' : 'translate-y-0'
          )}>
          <div>
            <div
              id="scale-icon"
              className={clsx(
                'relative mx-auto mb-8 flex h-16 w-16 items-center justify-center overflow-hidden bg-black-10 sm:mx-0',
                isHover ? 'bg-opacity-10' : 'bg-opacity-100'
              )}>
              <svg
                ref={boxScale}
                viewBox="0 0 36 36"
                fill="none"
                className={clsx('absolute h-9 w-9 transition-all', isHover ? 'text-white' : 'text-black-100')}
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.5 10.5H6.9C6.05992 10.5 5.63988 10.5 5.31901 10.6635C5.03677 10.8073 4.8073 11.0368 4.66349 11.319C4.5 11.6399 4.5 12.0599 4.5 12.9V29.1C4.5 29.9401 4.5 30.3601 4.66349 30.681C4.8073 30.9632 5.03677 31.1927 5.31901 31.3365C5.63988 31.5 6.05992 31.5 6.9 31.5H13.5M13.5 31.5H22.5M13.5 31.5L13.5 6.9C13.5 6.05992 13.5 5.63988 13.6635 5.31901C13.8073 5.03677 14.0368 4.8073 14.319 4.66349C14.6399 4.5 15.0599 4.5 15.9 4.5L20.1 4.5C20.9401 4.5 21.3601 4.5 21.681 4.66349C21.9632 4.8073 22.1927 5.03677 22.3365 5.31901C22.5 5.63988 22.5 6.05992 22.5 6.9V31.5M22.5 16.5H29.1C29.9401 16.5 30.3601 16.5 30.681 16.6635C30.9632 16.8073 31.1927 17.0368 31.3365 17.319C31.5 17.6399 31.5 18.0599 31.5 18.9V29.1C31.5 29.9401 31.5 30.3601 31.3365 30.681C31.1927 30.9632 30.9632 31.1927 30.681 31.3365C30.3601 31.5 29.9401 31.5 29.1 31.5H22.5"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h2
              className={clsx(
                ' text-center text-head-six font-medium  sm:mb-[72px] sm:text-left sm:text-head-five',
                isHover ? 'text-neutral-10' : 'text-black-100'
              )}>
              Scale your team quickly
            </h2>
          </div>

          <button
            className={clsx('flex items-center self-center text-small  font-medium sm:self-start', isHover ? 'text-neutral-10' : 'text-neutral-90')}>
            View More
            <svg className="ml-1 h-6 w-6" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.59766 7.30688L15.3205 7.30688L15.3205 13.0297"
                stroke={isHover ? 'white' : '#1E293B'}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.30664 15.3206L15.2404 7.38682"
                stroke={isHover ? 'white' : '#1E293B'}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </Link>
  );
};

export default ScaleTeam;
