import { StarIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

import React, { useState } from 'react';
import useWordLength from 'utils/useWordLength';

const Card = ({ star, word, name, job, onClick, active }) => {
  const wordBreak = useWordLength();
  const wordSubstring = () => {
    if (!active && word.length >= wordBreak) {
      return word.substring(0, wordBreak);
    } else {
      return word;
    }
  };
  console.log('wordBreak', wordBreak);

  return (
    <div
      className={clsx(
        'min-h-[402px] bg-white  p-8  shadow-recruitment-hero transition-all duration-200 sm:min-h-[406px]',
        active ? ' h-full xl:h-[546px]' : ' h-[402px] sm:h-[406px]'
      )}>
      <div className="flex h-full flex-col items-center justify-between">
        <div className="mb-4 flex gap-x-2">
          {Array(star)
            .fill()
            .map((_, i) => (
              <StarIcon key={i} className="h-[22px] w-[22px] fill-brand-other-main text-brand-other-main" />
            ))}
        </div>
        <div className="flex h-full flex-col justify-between">
          <p className="text-center font-switzer text-base tracking-wide text-black-90">
            {wordSubstring()} {!active && word.length >= wordBreak && '...'}
            <span
              className={clsx('text-base text-[#997839] hover:cursor-pointer', word.length >= wordBreak ? 'inline-block' : 'hidden')}
              onClick={onClick}>
              {active ? 'Show less' : ' Read more'}
            </span>
          </p>

          <div className="text-center">
            <p className={clsx('text-small font-semibold text-black-90 transition-all', active ? 'mt-[20px]' : 'mt-auto')}>
              {name}, <br className="block sm:hidden" /> <span className="font-normal text-black-60">{job}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
