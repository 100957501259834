import Consultation from 'components/contact/Consultation';
import ContactUs from 'components/contact/ContactUs';
import Hero from 'components/contact/Hero';
import WhereWe from 'components/contact/WhereWe';
import React from 'react';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - Talent and Recruitment Agency | Industry Talent</title>
        <meta
          name="description"
          content="Get in touch with a leading talent and recruitment agency. Our experienced recruiting firm is here to help you find the best talent in industrial businesses."
        />
        <meta name="keywords" content="talent agency, recruitment agency, recruiting firm" />
      </Helmet>
      <div className="overflow-hidden">
        <Hero />
        <WhereWe />
        <Consultation />
        <ContactUs />
      </div>
    </>
  );
};

export default Contact;
