import Container from 'components/common/Container';
import Label from 'components/common/Label';
import Grid from 'components/common/Grid';
import Section from 'components/common/Section';
import React from 'react';
import SofaImage from 'assets/about/sofa-hero.png';
import SofaImageMobile from 'assets/about/sofa-hero-mobile.png';
import PotImage from 'assets/about/pot-hero.png';
const HeroWhy = () => {
  return (
    <Section id="why-industry" className={'pb-12 pt-[144px] sm:pb-[80px] sm:pt-[176px] xl:pb-20 xl:pt-[192px]'}>
      <Container>
        <Grid>
          <div className="col-span-full sm:col-span-5 xl:col-span-6">
            <h1
              data-aos="fade-up"
              data-aos-delay="500"
              className="mb-6 text-head-four font-medium text-black-100 sm:mb-0 sm:text-head-three xl:text-head-two">
              Why Industry Talent?{' '}
            </h1>
          </div>
          <div className="col-span-full mb-20 flex flex-col gap-4 text-base text-neutral-60 sm:col-span-7 sm:mb-24 sm:gap-6 sm:text-large xl:col-span-6">
            <p data-aos="fade-up" data-aos-delay="1200" className="">
              We offer a personal, concierge-style, unique recruitment service for businesses who want the best talent in their team.{' '}
            </p>
            <p data-aos="fade-up" data-aos-delay="1400" className="">
              Take your organisation to the next level with only the best talent in the industry.{' '}
            </p>
          </div>
          <div className="relative z-[2] col-span-full h-[380px] sm:col-span-8 xl:col-span-7 xl:h-[425px]">
            <img
              src={SofaImage}
              data-aos="zoom-in"
              data-aos-delay="1100"
              className="hidden h-[380px] w-full object-cover sm:block xl:h-[425px]"
              alt="Sofa Image"
            />
            <img
              src={SofaImageMobile}
              data-aos="zoom-in"
              data-aos-delay="1100"
              className="block h-[380px] w-full object-cover sm:hidden xl:h-[425px]"
              alt="Sofa Image"
            />
          </div>
          <div className="relative z-[2] col-span-4 hidden h-[380px] sm:block xl:col-span-5 xl:h-[425px]">
            <img src={PotImage} data-aos="zoom-in" data-aos-delay="1100" className="h-[380px] w-full object-cover xl:h-[425px]" alt="Pot Image" />
          </div>
        </Grid>
      </Container>
    </Section>
  );
};

export default HeroWhy;
