import clsx from 'clsx';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Anchor from './Anchor';

const LinkItem = ({ data, order, ...props }) => {
  const { icon, title, className } = data;
  const [linkIsHover, setLinkIsHover] = useState('');
  const isHover = linkIsHover === 'scale';

  return (
    <>
      {/* <Link to="/start-hiring" className={clsx(' flex h-[256px] w-[327px] flex-col justify-between p-8 sm:h-[300px] sm:w-auto', className)} {...props}> */}
      {/* <div> */}
      {/* <img src={icon} alt="icon" className="mb-6 h-[48px] w-[48px]" /> */}
      {/* <h6 className="text-large font-medium text-black-100 ">{title}</h6> */}
      {/* </div> */}
      {/* <Anchor /> */}
      {/* </Link> */}
      <Link to="/start-hiring" className={order} onMouseOver={() => setLinkIsHover('scale')} onMouseLeave={() => setLinkIsHover('')} {...props}>
        <div className="relative">
          {/* Box Shadow */}
          <div
            className={clsx(
              'absolute left-4 top-6 ',
              'transition-all duration-700',
              isHover ? 'h-[256px] w-[327px] sm:h-[300px] sm:w-auto' : 'h-[0px] w-[0px]'
            )}></div>

          {/* Background */}
          <div className={clsx('absolute left-0 top-0 h-full w-full overflow-hidden ', className)}>
            <div
              className={clsx(
                'relative h-[200%] w-[200%] bg-brand-other-main',
                'left-[-50%] origin-center transform rounded-[50%] duration-[350ms]',
                'transition-all ease-out',
                isHover ? 'translate-y-[-7.5%] scale-100' : 'translate-y-0 scale-0'
              )}></div>
          </div>

          {/* Content */}
          <div
            className={clsx(
              'relative flex min-h-[256px] w-[327px] flex-col justify-between p-8 sm:min-h-[300px] sm:w-auto',
              'transform duration-500',
              isHover ? 'translate-y-[-2.5%]' : 'translate-y-0'
            )}>
            <div>
              <img src={icon} alt="icon" className="mb-6 h-[48px] w-[48px]" />
              <h6 className={clsx('max-w-[82%] text-large font-medium text-black-100 transition-all sm:max-w-[89.8%] xl:max-w-[91.4%]')}>{title}</h6>
            </div>

            <Anchor isHover={isHover} />
          </div>
        </div>
      </Link>
    </>
  );
};

export default LinkItem;
