const ListOffice = [
  {
    title: 'Brisbane Head Office',
    address: '85 Commercial Road, Teneriffe QLD 4005',
    map: 'https://www.google.co.id/maps/place/85+Commercial+Rd,+Teneriffe+QLD+4005,+Australia/@-27.4541407,153.0427039,17z/data=!3m1!4b1!4m5!3m4!1s0x6b9159910ea5efbf:0xdf30f615c4ebe8!8m2!3d-27.4541455!4d153.0448926'
  },
  {
    title: 'Sydney Head Office',
    address: '10 Carrington St, Sydney NSW 2000',
    map: 'https://www.google.co.id/maps/place/10+Carrington+St,+Sydney+NSW+2000,+Australia/@-33.8656829,151.2042982,17z/data=!3m1!4b1!4m5!3m4!1s0x6b12ae411cff7bcf:0xa1b7b2047c47b21d!8m2!3d-33.8656874!4d151.2064869'
  },
  {
    title: 'Melbourne Head Office',
    address: '412 St Kilda Rd, Melbourne VIC 3004',
    map: 'https://www.google.co.id/maps/place/412+St+Kilda+Rd,+Melbourne+VIC+3004,+Australia/@-37.8350344,144.9721174,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad6681d62a48519:0xf7e619a8d53120c!8m2!3d-37.8350387!4d144.9743061'
  }
];

export default ListOffice;
