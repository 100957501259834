import Button from 'components/common/Button';
import InputPrimary from 'components/common/InputPrimary';
import Modal from 'components/home/GetInTouch/Modal';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

const Form = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sector, setSector] = useState('Industry');
  const formRef = useRef();
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log('formRef', formRef.current);
    emailjs.sendForm('service_46djnbl', 'template_bsyv7u5', formRef.current, 'N0SI-LyAwmtVS2IjK').then(
      (result) => {
        console.log('result.text', result.text);
        setName('');
        setEmail('');
        setSector('');
        setMessage('');
        openModal();
      },
      (error) => {
        console.log(error.text);
        toast.error('Message failed to send.');
      }
    );
    console.log('list data', name, email, sector, message);
    // openModal();
  };

  return (
    <>
      <Toaster />
      <form
        ref={formRef}
        onSubmit={onSubmit}
        className="mx-auto w-full  max-w-[327px] border border-neutral-30 bg-white px-4 py-6 sm:max-w-[912px] sm:p-10 xl:max-w-[978px]">
        <h4 className="mb-4 text-large text-neutral-100 sm:mb-8 sm:text-[28px] sm:leading-[42px]">Send us a message</h4>
        <div className="flex w-full flex-col gap-4">
          <input
            name="from_name"
            id="from_name"
            placeholder="Your name"
            type="text"
            className="w-full max-w-[832px] border border-transparent bg-neutral-10 px-6 py-4 placeholder:text-neutral-50 focus:border-brand-primary-main focus:ring-0 xl:max-w-[898px]"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
          />
          <input
            name="reply_to"
            id="reply_to"
            placeholder="Email address"
            type="email"
            className="borde w-full border-transparent bg-neutral-10  px-6 py-4 placeholder:text-neutral-50 focus:border-brand-primary-main focus:ring-0"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
          <textarea
            name="message"
            id="message"
            placeholder="Message"
            className="h-[161px] w-full border border-transparent bg-neutral-10 px-6 py-4 placeholder:text-neutral-50
          focus:border-brand-primary-main focus:ring-0"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            value={message}
          />
        </div>
        <Button isPrimary className="mt-8 w-full sm:max-w-[200px]" type="submit">
          Submit
        </Button>
      </form>
      <Modal isOpen={isOpen} closeModal={closeModal} />
    </>
  );
};

export default Form;
