const ListTestimonial = [
  {
    star: 5,
    word: 'It was a great experience. From start to finish, Krystal was very supportive and kind. Krystal is extremely professional and highly involved throughout the whole process by giving accurate information when needed. Thank you so much I’m extremely grateful and so happy.',
    name: 'Michelle',
    job: 'Senior Property Manager – Real Estate'
  },
  {
    star: 5,
    word: 'My experience with Krystal was extremely positive. She is a top tier professional with business qualities that demand respect. Krystal was always timely and responsive, while providing valuable feedback.',
    name: 'Jessica',
    job: 'Senior Strata Manager'
  },
  {
    star: 5,
    word: 'Krystal is always very supportive and a pleasure to work with throughout the recruitment process. She understands the brief, who we are as an agency and always provides us with a good number of candidates to review/ interview. The team is great at keeping me informed of the candidate’s status and feedback throughout the process. I look forward to continuing our partnership with Industry Talent!',
    name: 'Jonathon',
    job: 'Principal - Real Estate'
  },
  {
    star: 5,
    word: 'Krystal was amazing to work with for my next career step. From the first contact with Krystal, she was always transparent, following up before and after each stage of the process and giving great advice. Krystal always cared about my personal requirements for my next step. I can highly recommend her to everyone. Thanks for the support!',
    name: 'Kim',
    job: 'Team Leader - Real Estate'
  },
  {
    star: 5,
    word: 'Building strong and lasting relationships with Recruitment Agencies is a key element of my role as People & Culture Manager. Industry talent have consistently offered a thorough and professional recruitment service, they are genuinely lovely people who care about what they do and about nurturing our relationship. Krystal has spent time getting to know our business, who we look for in a candidate and really understanding and tuning in to our needs. They have subsequently provided our company with a number of strong, relevant and highly valued team members and I would not hesitate to recommend them to anyone!',
    name: 'Tina',
    job: 'People and Culture Manager'
  },
  {
    star: 5,
    word: 'We have used Industry Talent many times to source a number of our staff members. Krystal has always provided excellent well screened candidates and applies a very personal approach to recruitment. I would have no hesitation in recommending the agency to anyone looking for an agency you can trust to deliver results.',
    name: 'Louise',
    job: 'Director – Body Corporate'
  }
];

export default ListTestimonial;
