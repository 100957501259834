import clsx from 'clsx';
import React from 'react';

const Card = ({ className, children }) => {
  return <div className={clsx('flex w-full max-w-[371px] flex-col bg-brand-other-surface p-4 sm:p-6', className)}>{children}</div>;
};

export const CardHeader = ({ children }) => {
  return <h2 className="text-base text-black-100 sm:text-large">{children}</h2>;
};
export default Card;
