import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import { ReactComponent as PersonIcon } from 'assets/job-details/person.svg';
import { ReactComponent as LinkedInIcon } from 'assets/job-details/linkedin-input.svg';
import { ReactComponent as EmailIcon } from 'assets/job-details/email.svg';

import '@react-pdf-viewer/core/lib/styles/index.css';

import InputGroup from './InputGroup';
import InputResume from './InputResume';
import InputPhone from './InputPhone';

import { clientRoot } from 'api';
import Button from 'components/common/Button';

const FormApply = ({ jobId, closeModal, setModalSuccess, isModalSuccess }) => {
  const [formApply, setFormAppy] = useState({
    email: '',
    name: '',
    phone: '',
    linkedin: ''
  });

  const handleChangeForm = (event) => {
    setFormAppy({
      ...formApply,
      [event.target.name]: event.target.value
    });
  };

  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('email', formApply.email);
    formData.append('name', formApply.name);
    formData.append('phone', formApply.phone);
    formData.append('linkedin', formApply.linkedin);
    formData.append('resume', selectedFile);

    clientRoot
      .post(`jobs/${jobId}/apply`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        setModalSuccess(true);
      })
      .catch(() => {
        toast.error('Failed');
      });

    closeModal();
  };

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit} className="border-b border-t border-neutral-20 ">
        <div className="space-y-[14px] p-4 sm:p-6">
          <InputGroup
            name="name"
            placeholder="Your name"
            type="text"
            label="Name"
            value={formApply.name}
            onChange={handleChangeForm}
            isRequired={true}
            className="text-small placeholder:text-small sm:text-base sm:placeholder:text-base"
            icon={<PersonIcon className="absolute left-3 top-3 h-5 w-5 sm:h-6 sm:w-6" />}
          />

          <InputPhone value={formApply.phone} onChange={handleChangeForm} />
          <InputGroup
            name="email"
            placeholder="Email address"
            type="email"
            label="Email"
            value={formApply.email}
            onChange={handleChangeForm}
            className="text-small placeholder:text-small sm:text-base sm:placeholder:text-base"
            isRequired={true}
            icon={<EmailIcon className="absolute left-3 top-3 h-5 w-5 sm:h-6 sm:w-6" />}
          />
          <InputGroup
            name="linkedin"
            placeholder="LinkedIn Profil URL"
            type="text"
            label="LinkedIn"
            value={formApply.linkedin}
            className="text-small placeholder:text-small sm:text-base sm:placeholder:text-base"
            onChange={handleChangeForm}
            icon={<LinkedInIcon className="absolute left-3 top-3 h-5 w-5 sm:h-6 sm:w-6" />}
          />

          <InputResume setSelectedFile={setSelectedFile} />
        </div>

        <div className="border-t border-t-neutral-30 p-4 sm:p-6">
          <Button isPrimary className="w-full text-base font-medium">
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

FormApply.propTypes = {
  jobId: PropTypes.number,
  closeModal: PropTypes.func
};

export default FormApply;
