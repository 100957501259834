import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Australia } from 'assets/job-details/australia.svg';

const InputPhone = ({ value, onChange }) => {
  return (
    <div>
      <label className="block text-xsmall text-black-100">
        Phone
        <span className="text-danger-500">*</span>
      </label>
      <div className="relative">
        <div className="absolute left-3 top-3 flex items-center justify-center gap-x-4">
          <SelectNation />
          <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="0.5" x2="0.499999" y2="23.5" stroke="#E2E8F0" strokeLinecap="round" />
          </svg>
        </div>
        <input
          type="number"
          name="phone"
          className="w-full rounded-lg border border-transparent bg-neutral-10 py-[12px] pl-16 pr-4 text-small font-medium text-black-100 placeholder:text-small placeholder:font-medium  placeholder:text-neutral-40 focus:border-brand-primary-main focus:ring-0 sm:text-base sm:placeholder:text-base"
          placeholder="Phone number"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const SelectNation = () => {
  return (
    <div className="flex cursor-pointer items-center gap-x-2">
      <Australia />
    </div>
  );
};

InputPhone.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default InputPhone;
