import clsx from 'clsx';
import React from 'react';

const Card = ({ className, Icon, title, description, ...props }) => {
  return (
    <div
      className={clsx(
        'flex flex-col items-center border border-neutral-30 bg-transparent px-6 py-8 sm:h-[272px] xl:h-[368px] xl:px-8 xl:py-10',
        className
      )}
      {...props}>
      <Icon className="mb-6 h-12 w-12 sm:h-16 sm:w-16" />
      <h6 className="mb-3 text-large font-medium text-black-100">{title}</h6>
      <p className="text-center text-small text-neutral-70"> {description}</p>
    </div>
  );
};

export default Card;
