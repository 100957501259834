import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Section from 'components/common/Section';
import React from 'react';
import FormSearch from './JobSearch/FormSearch';

import JobsImage from 'assets/jobs/hero.png';

const JobSearch = () => {
  return (
    <Section id="jobs-search" className="mt-[144px] sm:mt-[236px] xl:mt-[208px]">
      <Container>
        <Grid>
          <div className="col-span-full flex flex-col sm:col-span-7">
            <div className="flex w-full flex-col ">
              <h1
                data-aos="fade-up"
                data-aos-delay="300"
                className="mb-6 mt-0 text-head-three font-medium text-black-100 sm:text-[56px] sm:leading-[64px] xl:mb-4 xl:mt-[52px] xl:max-w-[430px] xl:text-head-one">
                Job Search
              </h1>
              <p data-aos="fade-up" data-aos-delay="500" className="mb-10 max-w-[440px] text-large text-black-60  sm:mb-12 xl:mb-10 xl:max-w-[425px]">
                Most of our jobs are not advertised. Register your interest to access unseen opportunities.
              </p>
            </div>
            <FormSearch />
          </div>
          <div className="col-span-5 col-end-13 hidden sm:block">
            <div
              data-aos="fade-up-right"
              data-aos-delay="900"
              data-aos-duration="1000"
              className="relative ml-auto h-[504px] w-[315px] bg-brand-other-surface xl:h-[504px] xl:w-[408px]">
              <div
                data-aos="fade-down-right"
                data-aos-delay="1100"
                data-aos-duration="1000"
                className="absolute -ml-[84px] -mt-6 h-[504px] w-[375px] xl:-ml-8 xl:-mt-8 xl:h-[504px] xl:w-[408px]">
                <img src={JobsImage} className="h-[504px] w-auto object-cover xl:h-[504px]" alt="" />
              </div>
            </div>
          </div>
        </Grid>
      </Container>
    </Section>
  );
};

export default JobSearch;
