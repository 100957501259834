import clsx from 'clsx';
import React, { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const Caption = ({ time, title, delayTime, index }) => {
  gsap.registerPlugin(ScrollTrigger);
  const pathRefTime = React.useRef(null);
  const pathRefTitle = React.useRef(null);
  useEffect(() => {
    gsap.fromTo(
      pathRefTime.current,
      {
        color: '#BCB7AC',
        duration: 0.5
      },
      {
        color: '#BCB7AC',
        duration: 0.5,
        delay: delayTime,
        ease: 'none',
        scrollTrigger: {
          trigger: '#time'
        }
      }
    );
    gsap.fromTo(
      pathRefTitle.current,
      {
        color: '#E0E0E0',
        duration: 0.5
      },
      {
        color: '#3D3D3D',
        duration: 0.5,
        delay: delayTime,
        ease: 'none',
        scrollTrigger: {
          trigger: '#title'
        }
      }
    );
  }, []);
  return (
    <div
      className={clsx(
        'absolute  mt-[36px]   focus:outline-none',
        index === 0 ? '-ml-[44px] w-[105px] xl:-ml-[83px] xl:w-[180px]' : '-ml-[75px] w-[153px] xl:-ml-[83px] xl:w-[180px]'
      )}>
      <p id="time" ref={pathRefTime} className="mb-2 hidden text-center text-xsmall font-semibold  tracking-wide  sm:block">
        {' '}
        {time}{' '}
      </p>
      <p id="title" ref={pathRefTitle} className="text-center text-base font-medium  xl:text-large">
        {title}
      </p>
    </div>
  );
};

export default Caption;
