import { BriefcaseIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Section from 'components/common/Section';
import React, { useEffect } from 'react';
import Card from './description/Card';
import { ReactComponent as BriefIcon } from 'assets/job-details/briefcase.svg';
import { ReactComponent as PhoneIcon } from 'assets/job-details/phone.svg';
import { ReactComponent as EmailIcon } from 'assets/job-details/mail.svg';

import { Link } from 'react-router-dom';

const Description = ({ data }) => {
  // console.log('Description', data);
  const { job_type: jobType, owners, documents, name, phone, email } = data;
  useEffect(() => {
    if (!data) return <div>lroem</div>;
  }, [data]);

  return (
    <Section id="job-description" className="py-12 sm:py-20 xl:py-24">
      <Container>
        <Grid>
          <div className="col-span-full sm:col-span-8">
            <h6 className="mb-6 text-head-six font-medium text-black-100">Job Description</h6>
            <div className="job-description mb-10 text-black-100 sm:mb-0" dangerouslySetInnerHTML={{ __html: data?.description }}></div>
          </div>
          <div className="col-span-full flex flex-col gap-6 sm:col-span-4">
            <Card className="flex flex-col gap-4 xl:gap-6">
              <h6 className="mb-2 text-large font-medium text-black-100 xl:mb-0">About this role</h6>
              <div className="flex items-center">
                <div className="mr-2 flex w-full max-w-[100px] items-center">
                  <BriefIcon className="mr-2 h-4 w-4 text-neutral-70" />
                  <p className="text-small font-medium text-black-100">Job type</p>
                </div>
                <p className="text-small font-medium text-neutral-60">{data?.job_type?.name || '-'}</p>
              </div>
              <div className="flex items-center">
                <div className="mr-2 flex w-full max-w-[100px] items-center">
                  <DocumentTextIcon className="mr-2 h-4 w-4 text-neutral-70" />
                  <p className="text-small font-medium text-black-100">Reference</p>
                </div>
                <p className="text-small font-medium text-neutral-60">{data?.documents?.length > 1 ? 'Documents' : '-'}</p>
              </div>
            </Card>
            <Card>
              <div className="relative mb-4 h-20 w-20 overflow-hidden rounded-full">
                <img src={data?.owners?.length ? data?.owners[0]?.avatar_original_url : '-'} className="h-20 w-full object-contain" alt="" />
              </div>
              <div className="mb-6 flex flex-col items-start justify-between sm:mb-4 sm:flex-row sm:items-center">
                <h5 className="mb-4 text-head-five font-medium text-black-100 sm:mb-6 xl:mb-0">
                  {data?.owners?.length ? data?.owners[0]?.name : '-'}
                </h5>
                <div className="itesm-center flex gap-4">
                  <a target="_blank" href="https://www.linkedin.com/company/industry-talent-au/" rel="noreferrer">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 2.838C1 2.35053 1.19365 1.88303 1.53834 1.53834C1.88303 1.19365 2.35053 1 2.838 1H21.16C21.4016 0.999608 21.6409 1.04687 21.8641 1.13907C22.0874 1.23127 22.2903 1.36661 22.4612 1.53734C22.6322 1.70807 22.7677 1.91083 22.8602 2.13401C22.9526 2.3572 23.0001 2.59643 23 2.838V21.16C23.0003 21.4016 22.9529 21.6409 22.8606 21.8642C22.7683 22.0875 22.6328 22.2904 22.462 22.4613C22.2912 22.6322 22.0884 22.7678 21.8651 22.8602C21.6419 22.9526 21.4026 23.0001 21.161 23H2.838C2.59655 23 2.35746 22.9524 2.1344 22.86C1.91134 22.7676 1.70867 22.6321 1.53798 22.4613C1.3673 22.2905 1.23193 22.0878 1.13962 21.8647C1.04731 21.6416 0.999869 21.4025 1 21.161V2.838ZM9.708 9.388H12.687V10.884C13.117 10.024 14.217 9.25 15.87 9.25C19.039 9.25 19.79 10.963 19.79 14.106V19.928H16.583V14.822C16.583 13.032 16.153 12.022 15.061 12.022C13.546 12.022 12.916 13.111 12.916 14.822V19.928H9.708V9.388ZM4.208 19.791H7.416V9.25H4.208V19.79V19.791ZM7.875 5.812C7.88105 6.08668 7.83217 6.35979 7.73124 6.61532C7.63031 6.87084 7.47935 7.10364 7.28723 7.30003C7.09511 7.49643 6.8657 7.65248 6.61246 7.75901C6.35921 7.86554 6.08724 7.92042 5.8125 7.92042C5.53776 7.92042 5.26579 7.86554 5.01255 7.75901C4.7593 7.65248 4.52989 7.49643 4.33777 7.30003C4.14565 7.10364 3.99469 6.87084 3.89376 6.61532C3.79283 6.35979 3.74395 6.08668 3.75 5.812C3.76187 5.27286 3.98439 4.75979 4.36989 4.38269C4.75539 4.00558 5.27322 3.79442 5.8125 3.79442C6.35178 3.79442 6.86961 4.00558 7.25512 4.38269C7.64062 4.75979 7.86313 5.27286 7.875 5.812Z"
                        fill="#575757"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <p className="mb-4 text-base text-neutral-80 ">
                Driven by a life-long passion for real estate and property, Krystal has been in the industry since 2014. This allows Krystal to have
                an understanding of her client’s needs drawn from personal experiences. Her experience combined with the completion of a Bachelor of
                Commerce gives her extensive well-rounded knowledge to match the right skill set.
              </p>
              <p className="mb-6 text-base text-neutral-80 ">
                Being an industry leader Krystal recognised the demand for quality and personalised recruitment tailored to the needs of the real
                estate and property industry.
              </p>
              <hr className="mb-6 border-neutral-30" />
              <div className="mb-4 flex w-full items-start ">
                <div className="flex w-[100px] flex-shrink-0 items-center gap-2">
                  <PhoneIcon className="h-4 w-4" />
                  <p className="text-small font-medium text-black-100">Contact</p>
                </div>
                <p className="break-all text-small font-medium text-neutral-60">
                  {data?.owners?.length && data?.owners[0]?.phone ? data?.owners[0]?.phone : '+61480096705'}
                </p>
              </div>
              <div className="flex w-full items-start ">
                <div className="flex w-[100px] flex-shrink-0 items-center gap-2">
                  <EmailIcon className="h-4 w-4" />
                  <p className="text-small font-medium text-black-100">Email</p>
                </div>
                <p className="break-all text-small font-medium text-neutral-60">{data?.owners?.length ? data?.owners[0]?.email : '-'}</p>
              </div>
            </Card>
          </div>
        </Grid>
      </Container>
    </Section>
  );
};

export default Description;
