import React, { useRef } from 'react';
import InputPrimary from 'components/common/InputPrimary';
import Button from 'components/common/Button';
import { ReactComponent as Australia } from 'assets/job-details/australia.svg';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

const FormHiring = () => {
  const formRef = useRef();
  const [values, setValues] = React.useState('');
  const onChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log('formRef', formRef.current);
    emailjs.sendForm('service_46djnbl', 'template_g1od6hn', formRef.current, 'N0SI-LyAwmtVS2IjK').then(
      (result) => {
        console.log('result.text', result.text);
        setValues('');
        toast.success('Message sent successfully.');
      },
      (error) => {
        console.log(error.text);
        toast.error('Message failed to send.');
      }
    );
    console.log('list data', values);
    // openModal();
  };
  return (
    <>
      <Toaster />
      <form
        ref={formRef}
        data-aos="fade-up"
        data-aos-delay="700"
        onSubmit={onSubmit}
        className="col-span-full flex flex-col gap-y-6 bg-black-10 px-6 py-8 sm:px-6  xl:p-8 ">
        <div className="flex w-full flex-col gap-y-6 sm:flex-row sm:gap-x-6">
          <div className="flex w-full flex-col sm:w-6/12">
            <h6 className="mb-4 text-large font-medium text-black-100 sm:mb-6">Contact Details</h6>
            <div className="flex flex-col gap-y-3 sm:gap-y-4">
              <InputPrimary
                className={'py-[13px] placeholder:text-black-50'}
                name="from_name"
                id="from_name"
                placeholder="Your name..."
                type="text"
                value={values.from_name}
                onChange={onChange}
              />
              <InputPrimary
                className={'py-[13px] placeholder:text-black-50'}
                name="reply_to"
                id="reply_to"
                placeholder="Your email..."
                type="email"
                value={values.reply_to}
                onChange={onChange}
              />
              <div className="relative">
                <div className="absolute left-3 top-3 flex items-center justify-center gap-x-4">
                  <SelectNation />
                  <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.5" y1="0.5" x2="0.499999" y2="23.5" stroke="#E2E8F0" strokeLinecap="round" />
                  </svg>
                </div>
                <InputPrimary
                  className={'w-full py-[13px] pl-[86px] pr-4 placeholder:text-black-50'}
                  name="phone_number"
                  id="phone_number"
                  placeholder="Phone number"
                  type="text"
                  value={values.phone_number}
                  onChange={onChange}
                />
              </div>
              <div className="flex w-full flex-col gap-y-3 sm:flex-row sm:gap-x-4">
                <InputPrimary
                  className={'w-full py-[13px] placeholder:text-black-50'}
                  name="company_name"
                  id="company_name"
                  placeholder="Company Name"
                  type="text"
                  value={values.company_name}
                  onChange={onChange}
                />
                <InputPrimary
                  className={'w-full py-[13px] placeholder:text-black-50'}
                  name="position_title"
                  id="position_title"
                  placeholder="Position Tittle"
                  type="text"
                  value={values.position_title}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col sm:w-6/12">
            <h6 className="mb-4 text-large font-medium text-black-100 sm:mb-6">Vacancy Details</h6>
            <div className="flex flex-col gap-y-3 sm:gap-y-4">
              <InputPrimary
                className={'py-[13px] placeholder:text-black-50'}
                name="position_vacancy"
                id="position_vacancy"
                placeholder="Position Tittle"
                type="text"
                value={values.position_vacancy}
                onChange={onChange}
              />
              <InputPrimary
                className={'py-[13px] placeholder:text-black-50'}
                name="locations"
                id="locations"
                placeholder="Locations"
                type="text"
                value={values.locations}
                onChange={onChange}
              />
              <InputPrimary
                className={'py-[13px] placeholder:text-black-50'}
                name="salary"
                id="salary"
                placeholder="Salary"
                type="text"
                value={values.salary}
                onChange={onChange}
              />
              <InputPrimary
                className={'py-[13px] placeholder:text-black-50'}
                name="comments"
                id="comments"
                placeholder="Comments"
                type="text"
                value={values.comments}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className="-mt-[8px] flex w-full justify-end sm:mt-0" type="submit">
          <Button isPrimary className="w-full sm:max-w-[240px]">
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

const SelectNation = () => {
  return (
    <div className="flex cursor-pointer items-center gap-x-2">
      <Australia />
    </div>
  );
};

export default FormHiring;
