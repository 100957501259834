import PropTypes from 'prop-types';
import { Toaster } from 'react-hot-toast';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';

import FormApply from './FormApply';
import Modal from 'components/home/GetInTouch/Modal';

const ApplyJobModal = forwardRef(({ jobId, childRef }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalSuccess, setModalSuccess] = useState(false);
  useImperativeHandle(childRef, () => ({
    openModal() {
      openModal();
    }
  }));

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 overflow-y-auto" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black-100 bg-opacity-50 " />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto sm:inset-x-0 sm:top-[5%]  xl:inset-0 ">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full   max-w-[327px] overflow-hidden bg-white  text-left align-middle transition-all sm:max-w-[480px]">
                  <div className="flex items-start justify-between p-4 sm:p-6">
                    <div>
                      <Dialog.Title as="h5" className="mb-1 text-large font-semibold text-black-100">
                        Apply Job
                      </Dialog.Title>

                      <Dialog.Description className="text-small text-neutral-50">
                        Please complete the form below for a position with us
                      </Dialog.Description>
                    </div>

                    <button onClick={() => setIsOpen(false)}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                          fill="#111827"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* Form */}
                  <FormApply jobId={jobId} closeModal={closeModal} setModalSuccess={setModalSuccess} isModalSuccess={isModalSuccess} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Toaster />
      <Modal isOpen={isModalSuccess} closeModal={() => setModalSuccess(false)} />
    </>
  );
});

ApplyJobModal.displayName = 'Apply Job Modal';

ApplyJobModal.propTypes = {
  jobId: PropTypes.number,
  childRef: PropTypes.node
};

export default ApplyJobModal;
