import React, { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const Circle = ({ delayTime }) => {
  gsap.registerPlugin(ScrollTrigger);
  const pathRef = React.useRef(null);
  useEffect(() => {
    gsap.fromTo(
      pathRef.current,
      {
        backgroundColor: '#ffffff',
        duration: 0.5
      },
      {
        backgroundColor: '#E7E2D7',
        duration: 0.5,
        delay: delayTime,
        ease: 'none',
        scrollTrigger: {
          trigger: '#circle-color'
        }
      }
    );
  }, []);
  return (
    <div
      id="circle-color"
      ref={pathRef}
      className="group relative z-[3] flex h-4 w-4 items-center justify-center rounded-full border-2 border-brand-primary-main "></div>
  );
};

export default Circle;
