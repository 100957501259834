import Container from 'components/common/Container';
import Section from 'components/common/Section';
import ListStep from 'constant/hiring/ListStep';
import React, { useEffect } from 'react';

import Step from './Process/Step';
import ListStepMobile from 'constant/hiring/ListStepMobile';

import FormHiring from './FormHiring';
import Grid from 'components/common/Grid';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ListStepDekstop from 'constant/hiring/ListStepDekstop';
import clsx from 'clsx';
import Stepper from './Stepper';
import Circle from './Stepper/Circle';
import Linemobile from './Stepper/LineMobile';
import CaptionMobile from './Stepper/CaptionMobile';

const YourTeam = () => {
  gsap.registerPlugin(ScrollTrigger);
  const pathRef = React.useRef(null);
  const pathRefMobile = React.useRef(null);
  useEffect(() => {
    gsap.fromTo(
      pathRef.current,
      {
        width: 0
      },
      {
        width: '100%',
        duration: 20,
        scrollTrigger: {
          trigger: '#line-black'
        }
      }
    );
    gsap.fromTo(
      pathRefMobile.current,
      {
        height: 0
      },
      {
        height: '100%',
        duration: 20,
        scrollTrigger: {
          trigger: '#line-black'
        }
      }
    );
  }, []);
  return (
    <Section id="your-team" className=" order-3 pb-10  sm:pb-[164px] sm:pt-[80px] xl:py-[96px]">
      <Container>
        <Grid>
          <div className="col-span-full mb-[40px] flex flex-col justify-center sm:col-start-1 sm:items-start xl:col-span-8 xl:col-start-3 xl:mb-[72px] xl:items-center">
            <div className="mx-auto w-full  text-center sm:mx-0 xl:mx-auto xl:max-w-[632px] xl:text-center">
              <h2 data-aos="fade-up" data-aos-delay="100" className="text-head-four font-medium text-black-100 sm:text-head-three xl:text-head-two ">
                Your Team, <br className="block sm:hidden" />
                Found Now.
              </h2>
            </div>
          </div>
          <div className="col-span-full mb-[56px]" data-aos="zoom-in" data-aos-delay="300">
            <Stepper />
          </div>

          {/* Mobile */}
          <div className="relative col-span-full mb-[24px] flex flex-col gap-y-7 sm:hidden" data-aos="zoom-in" data-aos-delay="500">
            {ListStep.map((item, index) => {
              return (
                <div className="flex w-full" key={index}>
                  <div className="relative mr-5 mt-[7px]">
                    <Circle delayTime={1.5 * index} />
                    <Linemobile index={index} delayTime={1.5 * index} />
                  </div>

                  <div className={clsx('w-full max-w-[291px]  pb-4', index === 5 ? '' : 'border-b border-neutral-30')}>
                    <CaptionMobile title={item.title} index={index} time={item.time} delayTime={1.5 * index} />
                  </div>
                </div>
              );
            })}
            {/* <hr className="absolute top-[21px] left-2 h-[480px] w-[1px] border-r border-black-30" />
            <hr ref={pathRefMobile} className="absolute top-[21px] left-2 z-[2] max-h-[479px] w-[1px] border-r border-brand-primary-main" /> */}
          </div>
          <FormHiring />
        </Grid>
      </Container>
    </Section>
  );
};

export default YourTeam;
