import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as LogoWhite } from 'assets/logo-white.svg';
import { ReactComponent as LogoWhiteText } from 'assets/logo-white-text.svg';
import { ReactComponent as LogoBlackText } from 'assets/logo-black-text.svg';
import { ReactComponent as LogoBlack } from 'assets/logo-black.svg';
import { ReactComponent as HamburgerIcon } from 'assets/navbar/hamburger.svg';
import { ReactComponent as CloseIcon } from 'assets/navbar/close.svg';
import { Disclosure, Transition } from '@headlessui/react';
import clsx from 'clsx';
import gsap, { Bounce } from 'gsap';

const Header = () => {
  const location = useLocation();
  const locationPath = location.pathname;
  // console.log(location.pathname);
  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'Start Hiring', href: '/start-hiring' },
    { name: 'Explore Jobs', href: '/jobs' },
    { name: 'About Us', href: '/about-us' },
    { name: 'Contact', href: '/contact-us' }
  ];
  const logo =
    locationPath === '/' || locationPath === '/contact-us' ? <LogoWhite className="h-[40px] w-auto" /> : <LogoBlack className="h-[40px] w-auto" />;
  const border = locationPath === '/' || locationPath === '/contact-us' ? 'border-white' : 'border-black-100';
  const [isHome, setHome] = useState(locationPath === '/');

  useEffect(() => {
    setHome(locationPath === '/' || locationPath === '/contact-us');
  }, [location]);

  const pathname = location.pathname.slice(1);
  const circleRef = useRef();
  const logoItZoomInRef = useRef();
  useEffect(() => {
    gsap.fromTo(
      circleRef.current,
      { strokeDashoffset: 356 },
      {
        strokeDashoffset: 54,
        duration: 2.5,
        delay: 0.2,
        ease: 'linear'
      }
    );
    gsap.fromTo(
      logoItZoomInRef.current,
      { scale: 0, transformOrigin: 'center' },
      {
        scale: 1,
        transformOrigin: 'center',
        duration: 0.4,
        delay: 1.1,
        ease: Bounce.easeOut
      }
    );
  }, [pathname]);

  return (
    <>
      <Disclosure as="nav" className="absolute left-0 right-0 top-0 z-10">
        {({ open }) => (
          <div className={clsx('w-full  transition-all ', open ? 'bg-white' : 'bg-transparent')}>
            <header className={clsx(' mx-auto  max-w-screen-xl   sm:px-[56px] xl:px-[40px]')}>
              <div
                className={clsx(
                  'grid h-[96px] w-full grid-cols-4 items-center justify-center gap-x-4 border-b border-opacity-20 px-6   py-[32px]  transition-all sm:h-auto  sm:grid-cols-9 sm:px-0 sm:py-0 xl:grid-cols-12 xl:gap-x-8',
                  open ? 'border-black-50' : border
                )}>
                <div className="col-span-3 sm:col-span-2">
                  <Link to="/">
                    {open ? (
                      <LogoBlack className="h-[40px] w-auto" />
                    ) : (
                      <div>
                        {locationPath === '/' || locationPath === '/contact-us' ? (
                          <div className="flex items-center">
                            <div className="relative flex items-center justify-center">
                              <svg
                                className="h-auto max-h-[40px] w-full max-w-[40px] -rotate-90"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none">
                                <circle
                                  ref={circleRef}
                                  cx="20"
                                  cy="20"
                                  r="18.5"
                                  stroke="#ffffff"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                  strokeDashoffset="54"
                                  strokeDasharray="356, 366"
                                />
                              </svg>
                              <div className="absolute">
                                <svg width="20" height="22" ref={logoItZoomInRef} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M12.2109 21.4343C8.34749 21.4343 5.20319 18.29 5.20319 14.4265V5.2518C5.20319 4.94195 5.45373 4.69141 5.76357 4.69141C6.07342 4.69141 6.32397 4.94195 6.32397 5.2518V14.4265C6.32397 17.6722 8.96526 20.3135 12.2109 20.3135C13.785 20.3135 15.2634 19.7014 16.3746 18.5902C17.4859 17.479 18.0979 16.0006 18.0979 14.4265V14.2238C18.0979 13.9139 18.3484 13.6634 18.6583 13.6634C18.9681 13.6634 19.2187 13.9139 19.2187 14.2238V14.4265C19.2187 16.2989 18.49 18.0585 17.1665 19.382C15.8429 20.7056 14.0834 21.4343 12.2109 21.4343V21.4343Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M5.76357 5.81261C5.45373 5.81261 5.20319 5.56206 5.20319 5.25222V1.12484C5.20319 0.815003 5.45373 0.564453 5.76357 0.564453C6.07342 0.564453 6.32397 0.815003 6.32397 1.12484V5.25222C6.32397 5.56206 6.07342 5.81261 5.76357 5.81261Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M18.6601 9.06805H9.09335C8.78351 9.06805 8.53296 8.8175 8.53296 8.50766C8.53296 8.19782 8.78351 7.94727 9.09335 7.94727H18.6601C18.97 7.94727 19.2205 8.19782 19.2205 8.50766C19.2205 8.8175 18.97 9.06805 18.6601 9.06805Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M1.97308 9.71545C2.63961 9.71545 3.17993 9.17513 3.17993 8.5086C3.17993 7.84208 2.63961 7.30176 1.97308 7.30176C1.30656 7.30176 0.766235 7.84208 0.766235 8.5086C0.766235 9.17513 1.30656 9.71545 1.97308 9.71545Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="ml-[8.5px]">
                              <LogoWhiteText />
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <div className="relative flex items-center justify-center">
                              <svg
                                className="h-auto max-h-[40px] w-full max-w-[40px] -rotate-90"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none">
                                <circle
                                  ref={circleRef}
                                  cx="20"
                                  cy="20"
                                  r="18.5"
                                  stroke="#121212"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                  strokeDashoffset="54"
                                  strokeDasharray="356, 366"
                                />
                              </svg>
                              <div className="absolute">
                                <svg width="20" height="22" ref={logoItZoomInRef} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M12.2109 21.4343C8.34749 21.4343 5.20319 18.29 5.20319 14.4265V5.2518C5.20319 4.94195 5.45373 4.69141 5.76357 4.69141C6.07342 4.69141 6.32397 4.94195 6.32397 5.2518V14.4265C6.32397 17.6722 8.96526 20.3135 12.2109 20.3135C13.785 20.3135 15.2634 19.7014 16.3746 18.5902C17.4859 17.479 18.0979 16.0006 18.0979 14.4265V14.2238C18.0979 13.9139 18.3484 13.6634 18.6583 13.6634C18.9681 13.6634 19.2187 13.9139 19.2187 14.2238V14.4265C19.2187 16.2989 18.49 18.0585 17.1665 19.382C15.8429 20.7056 14.0834 21.4343 12.2109 21.4343V21.4343Z"
                                    fill="#121212"
                                  />
                                  <path
                                    d="M5.76357 5.81261C5.45373 5.81261 5.20319 5.56206 5.20319 5.25222V1.12484C5.20319 0.815003 5.45373 0.564453 5.76357 0.564453C6.07342 0.564453 6.32397 0.815003 6.32397 1.12484V5.25222C6.32397 5.56206 6.07342 5.81261 5.76357 5.81261Z"
                                    fill="#121212"
                                  />
                                  <path
                                    d="M18.6601 9.06805H9.09335C8.78351 9.06805 8.53296 8.8175 8.53296 8.50766C8.53296 8.19782 8.78351 7.94727 9.09335 7.94727H18.6601C18.97 7.94727 19.2205 8.19782 19.2205 8.50766C19.2205 8.8175 18.97 9.06805 18.6601 9.06805Z"
                                    fill="#121212"
                                  />
                                  <path
                                    d="M1.97308 9.71545C2.63961 9.71545 3.17993 9.17513 3.17993 8.5086C3.17993 7.84208 2.63961 7.30176 1.97308 7.30176C1.30656 7.30176 0.766235 7.84208 0.766235 8.5086C0.766235 9.17513 1.30656 9.71545 1.97308 9.71545Z"
                                    fill="#121212"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="ml-[8.5px]">
                              <LogoBlackText />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Link>
                  {/* <Link to="/">{open ? <LogoBlack className="-mt-1 h-[40px] w-auto" /> : logo}</Link> */}
                </div>
                <div className="col-span-5 hidden gap-x-10 sm:flex xl:pl-4">
                  <Link
                    className={clsx(
                      ' relative z-[1] border-b py-7 font-switzer sm:py-[35px] ',
                      isHome ? 'text-white' : 'text-black-100',
                      location.pathname === '/start-hiring' ? 'border-black-100' : 'border-transparent'
                    )}
                    to="/start-hiring">
                    Start Hiring
                  </Link>
                  <Link
                    className={clsx(
                      'py-7 font-switzer ',
                      'relative z-[1] border-b sm:py-[35px]',
                      isHome ? 'text-white' : 'text-black-100',
                      location.pathname === '/jobs' || location.pathname.includes('jobs') ? 'border-black-100' : 'border-transparent'
                    )}
                    to="/jobs">
                    Explore Jobs
                  </Link>
                  <Link
                    className={clsx(
                      ' relative z-[1] border-b py-7 font-switzer sm:py-[35px]',
                      isHome ? 'text-white' : 'text-black-100',
                      location.pathname === '/about-us' ? 'border-black-100' : 'border-transparent'
                    )}
                    to="/about-us">
                    About Us
                  </Link>
                  <Link
                    className={clsx(
                      ' relative z-[1] border-b py-7 font-switzer sm:py-[35px]',
                      isHome ? 'text-white' : 'text-black-100',
                      location.pathname === '/contact-us' ? 'border-white' : 'border-transparent'
                    )}
                    to="/contact-us">
                    Contact
                  </Link>
                </div>
                <div className="col-span-2 col-end-10 hidden sm:flex xl:col-end-13">
                  <a
                    href="https://calendly.com/krystalindustrytalent/30min"
                    target="_blank"
                    className={clsx(' ml-auto border px-4 py-[11px]', isHome ? 'border-white text-white' : 'border-black-100 text-black-100')}
                    rel="noreferrer">
                    Book a Call
                  </a>
                </div>
                <div className="col-end-5 flex items-center justify-end sm:hidden">
                  <Disclosure.Button className={clsx(locationPath === '/' || locationPath === '/contact-us' ? 'text-white' : 'text-black-100')}>
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <CloseIcon className={clsx('block h-6 w-6 text-black-100')} aria-hidden="true" />
                    ) : (
                      <HamburgerIcon className={clsx('block h-6 w-6')} aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </header>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Disclosure.Panel className="bg-white sm:hidden">
                <div className="flex w-full flex-col gap-y-[3px] px-2 ">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={clsx(
                        item.current ? 'bg-gray-900 text-white' : 'text-black-100',
                        'block rounded-md px-3 py-4 text-center text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}>
                      {item.name}
                    </Link>
                  ))}
                  <a
                    href="https://calendly.com/krystalindustrytalent/30min"
                    target="_blank"
                    className="mx-auto my-4 flex h-[48px] w-full max-w-[125px] items-center justify-center border border-black-100  text-center font-medium text-black-100"
                    rel="noreferrer">
                    Book a Call
                  </a>
                </div>
              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </>
  );
};

export default Header;
