import React, { useEffect, useState } from 'react';
import Router from './Router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

const getFaviconPath = (isDarkMode = false) => {
  return `/favicon-${isDarkMode ? 'dark' : 'light'}.png`;
};

function App() {
  const [faviconHref, setFaviconHref] = useState('/favicon-light.png');

  useEffect(() => {
    // Get current color scheme.
    const matcher = window.matchMedia('(prefers-color-scheme: dark)');
    // Set favicon initially.
    setFaviconHref(getFaviconPath(matcher.matches));
    // Change favicon if the color scheme changes.
    matcher.onchange = () => setFaviconHref(getFaviconPath(matcher.matches));
    console.log(' matcher.matches', matcher.matches);
    console.log(' faviconHref', faviconHref);
  }, [faviconHref]);
  useEffect(() => {
    AOS.init({
      offset: 200, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-out', // default easing for AOS animations
      once: true
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Industry Talent</title>
        <link rel="icon" href={faviconHref} />
        <meta
          name="description"
          content="Find top talent in the Australian industry with Industry Talent. Our comprehensive database includes professionals from a range of fields, and our search function allows you to easily filter candidates by location, experience, and skills. We also offer tools and resources to help professionals grow their careers. Start your search today and discover the many talented professionals waiting to join your team or take the next step in their careers."
        />
        <meta
          name="keywords"
          content="industry talent, australian industry, job search, job board, resume writing, career growth, professionals, engineering, marketing, finance, hiring, job opportunities"
        />
        <meta property="og:url" content="/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Industry Talent" />
        <meta
          property="og:description"
          content="Find top talent in the Australian industry with Industry Talent. Our comprehensive database includes professionals from a range of fields, and our search function allows you to easily filter candidates by location, experience, and skills"
        />
        <meta property="og:image" content="/light/mstile-310x310.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Industry Talent" />
        <meta
          name="twitter:description"
          content="Find top talent in the Australian industry with Industry Talent. Our comprehensive database includes professionals from a range of fields, and our search function allows you to easily filter candidates by location, experience, and skills"
        />
        <meta name="twitter:image" content="/light/mstile-310x310.png" />
      </Helmet>
      <div className="App">
        <Router />
      </div>
    </>
  );
}

export default App;
