import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Section from 'components/common/Section';
import React from 'react';
import Card from './Value/Card';

import { ReactComponent as FlexibleIcon } from 'assets/about/flexible.svg';
import { ReactComponent as PassionIcon } from 'assets/about/passion.svg';
import { ReactComponent as EffortIcon } from 'assets/about/effort.svg';
import { ReactComponent as ExperienceIcon } from 'assets/about/experience.svg';

const Value = () => {
  const Values = [
    {
      icon: FlexibleIcon,
      title: 'Flexible',
      description:
        'We understand every business is unique. We, therefore, tailor our search to make sure we get the results you want within a short timeframe and with a long-term focus. We strive to connect candidates with our clients whose values align. '
    },
    {
      icon: PassionIcon,
      title: 'Passion',
      description:
        'We are your biggest fans! We fall in love with the roles we recruit for and the candidates we represent. We can’t help but recruit with our hearts.'
    },
    {
      icon: EffortIcon,
      title: 'Effort',
      description:
        'We always put in 110%, striving to exceed our own expectations on every task. And we never give up–always delivering on promises, no matter how challenging the requirements. We consistently achieve outstanding results.   '
    },
    {
      icon: ExperienceIcon,
      title: 'Experience',
      description:
        'Our consultants are all industry specialists. Their knowledge is drawn from many years of personal experience. We ensure that we understand exactly what is required from both our clients and candidates. '
    }
  ];
  return (
    <Section id="value" className={'relative'}>
      <div className="absolute bottom-0 left-0 right-0  -mt-[227px] h-[1647px] bg-brand-other-surface sm:bottom-auto sm:-mt-[297px] sm:h-[1097px] xl:-mt-[290px] xl:h-[898px]"></div>
      <Container className={'relative z-[2]'}>
        <Grid className="gap-y-8 pb-[80px] xl:gap-y-0">
          <div className="col-span-full mb-2 flex items-center justify-center sm:mb-8 xl:mb-16">
            <h4 data-aos="fade-up" data-aos-delay="300" className="text-head-five font-medium text-black-100 xl:text-head-four">
              These are <br /> Our Values
            </h4>
          </div>

          {Values.map((item, index) => {
            return (
              <div className="col-span-full sm:col-span-6 xl:col-span-3" key={index}>
                <Card Icon={item.icon} title={item.title} description={item.description} data-aos="flip-left" data-aos-delay={500 + index * 200} />
              </div>
            );
          })}
        </Grid>
      </Container>
    </Section>
  );
};

export default Value;
