import create from 'zustand';

const queryStore = create(
  (set) => ({
    // filter keyword

    queryTitle: '',
    setQueryTitle: (params) => set({ queryTitle: params }),
    queryLocation: '',
    setQueryLocation: (params) => set({ queryLocation: params }),
    queryKeyword: '',
    setQueryKeyword: (params) => set({ queryKeyword: params })
  }),
  {
    name: 'industry-store'
  }
);

export default queryStore;
