import AccessIconAnimation from 'animation/hiring/AccessIconAnimation';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Access = ({ handleScroll }) => {
  const [linkIsHover, setLinkIsHover] = useState('');
  const isHover = linkIsHover === 'scale';
  const pathRef = React.useRef(null);
  AccessIconAnimation(pathRef);
  return (
    <Link to="/about-us" onMouseOver={() => setLinkIsHover('scale')} onMouseLeave={() => setLinkIsHover('')}>
      <div className="relative h-[303px] sm:h-[359px] xl:h-[320px]">
        {/* Box Shadow */}
        <div
          className={clsx(
            'absolute left-4 top-6 bg-brand-other-main bg-opacity-[0.26] blur-[80px]',
            'transition-all duration-700',
            isHover ? 'h-[272px] w-[236px]' : 'h-[0px] w-[0px]'
          )}></div>

        {/* Background */}
        <div className={clsx('absolute left-0 top-0 h-full w-full overflow-hidden border border-black-30 bg-white')}>
          <div
            className={clsx(
              'relative h-[200%] w-[200%] bg-brand-other-main',
              'left-[-50%] origin-center transform rounded-[50%] duration-[350ms]',
              'transition-all ease-out',
              isHover ? 'translate-y-[-7.5%] scale-100' : 'translate-y-0 scale-0'
            )}></div>
        </div>

        {/* Content */}
        <div
          className={clsx(
            'relative flex min-h-[303px] flex-col justify-between px-6 py-6 sm:min-h-[359px] xl:min-h-[320px]',
            'transform duration-500',
            isHover ? 'translate-y-[-2.5%]' : 'translate-y-0'
          )}>
          <div>
            <div
              className={clsx(
                'relative mx-auto mb-8 flex h-16 w-16 items-center justify-center overflow-hidden bg-black-10 sm:mx-0',
                isHover ? 'bg-opacity-10' : 'bg-opacity-100'
              )}>
              <svg
                id="access-icon"
                className={clsx('absolute h-9 w-9 transition-all', isHover ? 'text-white' : 'text-black-100')}
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                ref={pathRef}>
                <path
                  d="M31.5 31.5H6.9C6.05992 31.5 5.63988 31.5 5.31901 31.3365C5.03677 31.1927 4.8073 30.9632 4.66349 30.681C4.5 30.3601 4.5 29.9401 4.5 29.1V4.5M31.5 10.5L23.3485 18.6515C23.0515 18.9485 22.903 19.097 22.7318 19.1526C22.5811 19.2016 22.4189 19.2016 22.2682 19.1526C22.097 19.097 21.9485 18.9485 21.6515 18.6515L18.8485 15.8485C18.5515 15.5515 18.403 15.403 18.2318 15.3474C18.0811 15.2984 17.9189 15.2984 17.7682 15.3474C17.597 15.403 17.4485 15.5515 17.1515 15.8485L10.5 22.5M31.5 10.5H25.5M31.5 10.5V16.5"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h2
              className={clsx(
                ' text-center text-head-six font-medium  sm:mb-[72px] sm:text-left sm:text-head-five',
                isHover ? 'text-neutral-10' : 'text-black-100'
              )}>
              Access & Monitor top talent
            </h2>
          </div>

          <button
            className={clsx('flex items-center self-center text-small  font-medium sm:self-start', isHover ? 'text-neutral-10' : 'text-neutral-90')}>
            View More
            <svg className="ml-1 h-6 w-6" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.59766 7.30688L15.3205 7.30688L15.3205 13.0297"
                stroke={isHover ? 'white' : '#1E293B'}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.30664 15.3206L15.2404 7.38682"
                stroke={isHover ? 'white' : '#1E293B'}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </Link>
  );
};

export default Access;
