import { Disclosure, Transition } from '@headlessui/react';
import { clientRoot } from 'api';
import clsx from 'clsx';
import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Section from 'components/common/Section';
import React, { useEffect, useMemo, useState } from 'react';

import { BusinessSector, ContractType, JobLocation, useFilterJob } from './Filter';
import Keyword from './Filter/Keyword';
import JobCard from './JobCard';
import Pagination from './Pagination';
import JobCardSkeleton from './Skeleton/JobCardSkeleton';

import { ReactComponent as FilterIcon } from 'assets/jobs/filter.svg';
import queryStore from 'store/store';
import useResponsive from 'utils/useResponsive';
const JobListing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const { sm } = useResponsive();
  const [showResult, setShowResult] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);

  const [filterBusinessType, setFilterBusinessType] = useState([]);
  const [filterJobType, setFilterJobType] = useState([]);
  const [filterJobLocation, setFilterJobLocation] = useState([]);

  const [queryTitle, setQueryTitle, queryLocation, setQueryLocation, queryKeyword, setQueryKeyword] = queryStore((state) => [
    state.queryTitle,
    state.setQueryTitle,
    state.queryLocation,
    state.setQueryLocation,
    state.queryKeyword,
    state.setQueryKeyword
  ]);

  const filterJob = useFilterJob(
    jobs,
    filterBusinessType,
    filterJobType,
    filterJobLocation,
    {
      title: queryTitle,
      location: queryLocation
    },
    queryKeyword
  );

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * showResult;
    const lastPageIndex = firstPageIndex + showResult;
    if (filterJob.length > 8 && sm) {
      return filterJob.slice(firstPageIndex, lastPageIndex);
    } else if (filterJob.length > 5 && !sm) {
      return filterJob.slice(firstPageIndex, lastPageIndex);
    } else {
      return filterJob;
    }
  }, [currentPage, filterJob]);
  console.log('sm', sm);
  useEffect(() => {
    clientRoot.get('jobs?per_page=100&published_at_sort=desc&status=active').then((response) => {
      setJobs(response.data.results);
      setIsLoading(false);
    });
  }, [showResult, currentPage]);
  useEffect(() => {
    if (sm) {
      setShowResult(8);
    } else {
      setShowResult(5);
    }
  }, [sm]);
  const onClearFilter = () => {
    setFilterBusinessType([]);
    setFilterJobType([]);
    setFilterJobLocation([]);
    setQueryTitle('');
    setQueryLocation('');
    setQueryKeyword('');
  };
  console.log('filterJob', filterJob);
  return (
    <Section id="job-listing" className={'pb-12 pt-[48px] sm:py-[80px] xl:py-[96px]'}>
      <Container>
        <Grid>
          <div className="col-span-full sm:col-span-4">
            {/* Dekstop Filter */}
            <div className="hidden flex-col gap-y-4 sm:flex">
              <div className="mx-auto flex w-full max-w-[372px] justify-between pb-2">
                <h6 className="text-head-six font-medium text-black-100">Filter Job Board</h6>
                <button className="mx-2 text-base font-medium tracking-wide text-neutral-50" onClick={() => onClearFilter()}>
                  CLEAR
                </button>
              </div>
              <Keyword />
              <JobLocation jobs={jobs} setFilterJobLocation={setFilterJobLocation} filterJobLocation={filterJobLocation} />
              <ContractType jobs={jobs} setFilterJobType={setFilterJobType} filterJobType={filterJobType} />
              <BusinessSector jobs={jobs} setFilterBusinessType={setFilterBusinessType} filterBusinessType={filterBusinessType} />
            </div>
            {/* Mobile Filter */}
            <div className="mb-6 flex flex-col sm:hidden">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full items-center justify-center bg-neutral-10 py-[10px] text-large font-medium text-black-100  focus:outline-none focus-visible:ring-0 focus-visible:ring-opacity-75">
                      <span>Filter Job Board</span>
                      <FilterIcon className={`${open ? 'rotate-180 transform' : ''} ml-4 h-5 w-5 text-neutral-70 transition-all`} />
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0">
                      <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                        <div className="flex flex-col gap-y-2 sm:gap-y-4">
                          <div className="mx-auto flex w-full max-w-[372px] justify-end pb-2">
                            <button className="mx-2 text-small font-medium tracking-wide text-neutral-50 sm:text-base">CLEAR</button>
                          </div>
                          <Keyword />
                          <JobLocation jobs={jobs} setFilterJobLocation={setFilterJobLocation} filterJobLocation={filterJobLocation} />
                          <ContractType jobs={jobs} setFilterJobType={setFilterJobType} filterJobType={filterJobType} />
                          <BusinessSector jobs={jobs} setFilterBusinessType={setFilterBusinessType} filterBusinessType={filterBusinessType} />
                        </div>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
          <div className="col-span-full sm:col-span-8">
            <div className="flex flex-col gap-y-6 xl:mb-12">
              {isLoading ? (
                <>
                  <JobCardSkeleton />
                  <JobCardSkeleton />
                  <JobCardSkeleton />
                  <JobCardSkeleton />
                  <JobCardSkeleton />
                  <JobCardSkeleton />
                  <JobCardSkeleton />
                  <JobCardSkeleton />
                </>
              ) : currentTableData.length > 0 ? (
                currentTableData.map((job, index) => {
                  return <JobCard key={index} index={index} data={job} />;
                })
              ) : (
                <div className="text-center text-head-four font-medium text-neutral-60"> Data Not Found </div>
              )}
            </div>
            <div className="hidden w-full xl:flex">
              <Pagination
                currentPage={currentPage}
                totalCount={filterJob.length}
                totalPages={filterJob.length / showResult}
                pageSize={showResult}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
          <div className="col-span-full">
            <div className=" mt-6 flex w-full sm:mt-20 xl:hidden">
              <Pagination
                currentPage={currentPage}
                totalCount={filterJob.length}
                totalPages={filterJob.length / showResult}
                pageSize={showResult}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        </Grid>
      </Container>
    </Section>
  );
};

export default JobListing;
