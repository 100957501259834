import Section from 'components/common/Section';
import React from 'react';
import { ReactComponent as TopLeft } from 'assets/home/callToAction/ornament/top-left.svg';
import { ReactComponent as BottomRight } from 'assets/home/callToAction/ornament/bottom-right.svg';
const CallToAction = () => {
  return (
    <Section id="call-to-action" className="bg-brand-primary-main">
      <div className="relative mx-auto w-full max-w-[327px] overflow-hidden py-12 sm:max-w-[1440px] sm:py-[94px] xl:py-[114px]">
        <div data-aos="fade-down-right" data-aos-delay="300" className="absolute left-0 top-0 hidden sm:block">
          <TopLeft />
        </div>
        <div className="flex flex-col items-center justify-center">
          <h3 data-aos="zoom-in" data-aos-delay="200" className="mb-4 text-center text-head-four font-medium text-white sm:text-head-three">
            Start Hiring Now!
          </h3>
          <p data-aos="zoom-in" data-aos-delay="300" className="mb-10 text-base text-white-rgba-56 sm:text-large">
            Let us help you grow
          </p>
          <a
            data-aos="zoom-in"
            data-aos-delay="500"
            href="https://calendly.com/krystalindustrytalent/30min"
            target="_blank"
            rel="noreferrer"
            className="flex w-full max-w-[200px] items-center justify-center border border-white bg-transparent py-4 font-ranade text-medium font-medium text-white">
            Book a Call
          </a>
        </div>
        <div data-aos="fade-up-left" data-aos-delay="300" className="absolute bottom-0 right-0 hidden sm:block">
          <BottomRight />
        </div>
      </div>
    </Section>
  );
};

export default CallToAction;
