import { useEffect, useState } from 'react';
import useResponsive from './useResponsive';

const useWordLength = () => {
  const { sm, xl } = useResponsive();
  const [wordLength, setWordLength] = useState(400);
  useEffect(() => {
    if (xl) {
      setWordLength(400);
    } else if (sm) {
      setWordLength(245);
    } else {
      setWordLength(290);
    }
  }, [sm, xl]);
  return wordLength;
};

export default useWordLength;
