import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Section from 'components/common/Section';
import React from 'react';

const Consultation = () => {
  return (
    <Section id="consultation" className="bg-brand-other-surface py-12 sm:py-16">
      <Container>
        <Grid>
          <div className="col-span-full xl:col-span-10 xl:col-start-2 xl:px-1">
            <div className="flex flex-col border border-neutral-30 p-5 sm:flex-row sm:items-center sm:p-10">
              <div className="flex w-full max-w-[572px] flex-col">
                <h3 className="mb-4 text-head-four font-medium text-black-100 sm:text-head-three">Need a Consultation?</h3>
                <p className="text-base text-black-60 sm:text-large">
                  Industry Talent would love to hear from you. Book in a coffee with one of our specialists today.
                </p>
              </div>
              <a
                href="https://calendly.com/krystalindustrytalent/30min"
                target="_blank"
                className="text-white-100 mt-8 flex h-14 w-full max-w-[200px] items-center justify-center bg-brand-primary-main px-8 py-3 text-center text-base font-medium text-white transition-all hover:bg-brand-other-main sm:ml-auto sm:mt-0"
                rel="noreferrer">
                Book a Coffee
              </a>
            </div>
          </div>
        </Grid>
      </Container>
    </Section>
  );
};

export default Consultation;
