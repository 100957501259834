import clsx from 'clsx';
import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Section from 'components/common/Section';
import ListOffice from 'constant/contact/ListOffice';
import React, { useEffect, useRef } from 'react';
import { ReactComponent as LiukImageTablet } from 'assets/contact/liuk.svg';
import { ReactComponent as LiukImageDekstop } from 'assets/contact/liuk-dekstop.svg';
import { ReactComponent as LiukImageMobile } from 'assets/contact/liuk-mobile.svg';
import { ReactComponent as CircleDekstop } from 'assets/contact/circle-dekstop.svg';
import { ReactComponent as CircleTablet } from 'assets/contact/circle.svg';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const WhereWe = () => {
  const lineRef1 = useRef();
  const lineRef2 = useRef();
  const lineRef3 = useRef();
  const lineRef4 = useRef();
  const lineRef5 = useRef();
  const lineRef6 = useRef();
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.fromTo(
      lineRef1.current,
      { strokeDasharray: 800, strokeDashoffset: 800 },
      {
        strokeDashoffset: 0,
        duration: 6,
        scrollTrigger: '#map-section'
      }
    );
    gsap.fromTo(
      lineRef2.current,
      { strokeDasharray: 800, strokeDashoffset: 800 },
      {
        strokeDashoffset: 0,
        duration: 6,
        scrollTrigger: '#map-section'
      }
    );
    gsap.fromTo(
      lineRef3.current,
      { strokeDasharray: 800, strokeDashoffset: 800 },
      {
        strokeDashoffset: 0,
        duration: 6,
        scrollTrigger: '#map-section'
      }
    );
    gsap.fromTo(
      lineRef4.current,
      { strokeDasharray: 800, strokeDashoffset: 800 },
      {
        strokeDashoffset: 0,
        duration: 6,
        scrollTrigger: '#map-section'
      }
    );
    gsap.fromTo(
      lineRef5.current,
      { strokeDasharray: 800, strokeDashoffset: 800 },
      {
        strokeDashoffset: 0,
        duration: 6,
        scrollTrigger: '#map-section'
      }
    );
    gsap.fromTo(
      lineRef6.current,
      { strokeDasharray: 800, strokeDashoffset: 800 },
      {
        strokeDashoffset: 0,
        duration: 6,
        scrollTrigger: '#map-section'
      }
    );
  }, []);

  return (
    <Section id="where-we" className="relative overflow-hidden py-12 sm:py-20 xl:py-[96px]">
      <div className="absolute left-0 right-0 top-0 z-[3] h-[473px] bg-black-10 sm:h-[557px] xl:h-[644px]"></div>
      <div className="absolute right-0 top-0 z-[4] block">
        <svg width="436" height="534" viewBox="0 0 436 534" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M118.248 444.27L121.133 356.059C122.672 309.037 149.398 266.482 191.086 244.675L269.291 203.767L347.496 162.859C389.185 141.052 415.911 98.4967 417.449 51.4743L420.335 -36.7365"
            stroke="#F1EFEF"
            strokeWidth="2.46923"
            strokeLinecap="round"
            strokeLinejoin="round"
            ref={lineRef4}
          />
          <path
            d="M94.1972 429.166L97.0828 340.955C98.6211 293.933 125.347 251.377 167.036 229.57L245.241 188.662L323.446 147.754C365.134 125.948 391.86 83.3922 393.399 36.3698L396.284 -51.8409"
            stroke="#F1EFEF"
            strokeWidth="2.46923"
            strokeLinecap="round"
            strokeLinejoin="round"
            ref={lineRef5}
          />
          <path
            d="M70.1468 414.061L73.0324 325.85C74.5707 278.828 101.297 236.273 142.985 214.466L221.19 173.558L299.395 132.65C341.084 110.843 367.81 68.2877 369.348 21.2653L372.234 -66.9454"
            stroke="#F1EFEF"
            strokeWidth="2.46923"
            strokeLinecap="round"
            strokeLinejoin="round"
            ref={lineRef6}
          />
          <path
            d="M142.298 459.374L145.184 371.163C146.722 324.141 173.448 281.586 215.137 259.779L293.342 218.871L371.547 177.963C413.235 156.156 439.961 113.601 441.499 66.5783L444.385 -21.6325"
            stroke="#F1EFEF"
            strokeWidth="2.46923"
            strokeLinecap="round"
            strokeLinejoin="round"
            ref={lineRef3}
          />
          <path
            d="M166.348 474.479L169.234 386.268C170.772 339.246 197.498 296.69 239.187 274.883L317.392 233.975L395.597 193.067C437.285 171.261 464.012 128.705 465.55 81.6828L468.435 -6.52796"
            stroke="#F1EFEF"
            strokeWidth="2.46923"
            strokeLinecap="round"
            strokeLinejoin="round"
            ref={lineRef2}
          />
          <path
            d="M190.398 489.583L193.284 401.372C194.822 354.35 221.548 311.794 263.237 289.987L341.442 249.079L419.647 208.171C461.335 186.365 488.061 143.809 489.6 96.7868L492.485 8.57604"
            stroke="#F1EFEF"
            strokeWidth="2.46923"
            strokeLinecap="round"
            strokeLinejoin="round"
            ref={lineRef1}
          />
        </svg>
      </div>
      {/* <LiukImageTablet className="absolute top-0 right-0 z-[4] hidden sm:block xl:hidden" /> */}
      {/* <LiukImageDekstop id="dekstop" className="absolute top-0 right-0 z-[4] hidden sm:hidden xl:block" /> */}
      {/* <LiukImageMobile id="dekstop" className="absolute top-0 right-0 z-[4] block sm:hidden " /> */}
      <CircleDekstop
        className="absolute left-0 top-0 z-[4] hidden xl:block"
        data-aos="fade-down-right"
        data-aos-delay="400"
        data-aos-duration="2000"
      />
      <CircleTablet
        className="absolute left-0 top-0 z-[4] hidden sm:block xl:hidden"
        data-aos="fade-down-right"
        data-aos-delay="400"
        data-aos-duration="2000"
      />
      <Container className="relative z-[4]">
        <Grid>
          <div className="col-span-full sm:col-span-10 sm:col-start-2">
            <div className="mx-auto flex w-full max-w-[900px] flex-col">
              <h2 data-aos="fade-up" data-aos-delay="300" className="mb-4 text-center text-head-four font-medium text-black-100 sm:text-head-two">
                Where are we?
              </h2>
              <p data-aos="fade-up" data-aos-delay="500" className="mb-10 text-center text-base text-neutral-60 sm:mb-16 sm:text-large">
                Come visit us
              </p>
            </div>
          </div>
          <div className="relative z-[2] col-span-full xl:col-span-10 xl:col-start-2">
            <div className="mx-auto flex w-full max-w-[327px] flex-col sm:max-w-[912px] xl:max-w-[978px]">
              <div className="h-[505px] w-full" id="map-section">
                <iframe
                  className={clsx('h-[505px] w-full')}
                  id="gmap_canvas"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7080.971004789766!2d153.0447544!3d-27.4541407!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9159910ea5efbf%3A0xdf30f615c4ebe8!2s85%20Commercial%20Rd%2C%20Teneriffe%20QLD%204005%2C%20Australia!5e0!3m2!1sid!2sid!4v1674109994266!5m2!1sid!2sid"></iframe>
              </div>
              <div className="flex flex-col divide-y divide-neutral-30 border border-neutral-30 ">
                {ListOffice.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col items-start justify-between gap-8 px-4 py-6 sm:flex-row sm:items-center sm:gap-0 sm:p-8">
                      <div className="flex flex-col">
                        <h6 className="mb-2 text-large font-medium text-black-100 sm:text-head-six">{item.title}</h6>
                        <p className="text-base text-neutral-70 sm:text-large">{item.address}</p>
                      </div>
                      <a
                        href={item.map}
                        target="_blank"
                        className="flex h-[56px] w-full max-w-[295px] items-center justify-center border border-black-100 font-ranade text-base font-medium leading-normal text-black-100 sm:max-w-[232px] xl:max-w-[295px]"
                        rel="noreferrer">
                        Get Directions
                      </a>
                    </div>
                  );
                })}
                <div className="flex flex-col gap-3 p-8 sm:flex-row sm:items-center sm:gap-8">
                  <div className="flex items-center gap-4">
                    <svg width="24" height="24" className=" h-5 w-5 sm:h-6 sm:w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.0497 6C15.0264 6.19057 15.924 6.66826 16.6277 7.37194C17.3314 8.07561 17.8091 8.97326 17.9997 9.95M14.0497 2C16.0789 2.22544 17.9713 3.13417 19.4159 4.57701C20.8606 6.01984 21.7717 7.91101 21.9997 9.94M10.2266 13.8631C9.02506 12.6615 8.07627 11.3028 7.38028 9.85323C7.32041 9.72854 7.29048 9.66619 7.26748 9.5873C7.18576 9.30695 7.24446 8.96269 7.41447 8.72526C7.46231 8.65845 7.51947 8.60129 7.63378 8.48698C7.98338 8.13737 8.15819 7.96257 8.27247 7.78679C8.70347 7.1239 8.70347 6.26932 8.27247 5.60643C8.15819 5.43065 7.98338 5.25585 7.63378 4.90624L7.43891 4.71137C6.90747 4.17993 6.64174 3.91421 6.35636 3.76987C5.7888 3.4828 5.11854 3.4828 4.55098 3.76987C4.2656 3.91421 3.99987 4.17993 3.46843 4.71137L3.3108 4.86901C2.78117 5.39863 2.51636 5.66344 2.31411 6.02348C2.08969 6.42298 1.92833 7.04347 1.9297 7.5017C1.93092 7.91464 2.01103 8.19687 2.17124 8.76131C3.03221 11.7947 4.65668 14.6571 7.04466 17.045C9.43264 19.433 12.295 21.0575 15.3284 21.9185C15.8928 22.0787 16.1751 22.1588 16.588 22.16C17.0462 22.1614 17.6667 22 18.0662 21.7756C18.4263 21.5733 18.6911 21.3085 19.2207 20.7789L19.3783 20.6213C19.9098 20.0898 20.1755 19.8241 20.3198 19.5387C20.6069 18.9712 20.6069 18.3009 20.3198 17.7333C20.1755 17.448 19.9098 17.1822 19.3783 16.6508L19.1835 16.4559C18.8339 16.1063 18.6591 15.9315 18.4833 15.8172C17.8204 15.3862 16.9658 15.3862 16.3029 15.8172C16.1271 15.9315 15.9523 16.1063 15.6027 16.4559C15.4884 16.5702 15.4313 16.6274 15.3644 16.6752C15.127 16.8453 14.7828 16.904 14.5024 16.8222C14.4235 16.7992 14.3612 16.7693 14.2365 16.7094C12.7869 16.0134 11.4282 15.0646 10.2266 13.8631Z"
                        stroke="#46332D"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p className="text-base text-black-90 sm:text-large">(+61)0480 096 705</p>
                  </div>
                  <div className="flex items-center gap-4">
                    <svg width="24" height="24" className=" h-5 w-5 sm:h-6 sm:w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
                        stroke="#46332D"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <p className="text-base text-black-90 sm:text-large">krystal@industrytalent.com.au</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Container>
    </Section>
  );
};

export default WhereWe;
