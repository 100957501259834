import ElevateIconAnimation from 'animation/hiring/ElevateIconAnimation';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const Elevate = ({ handleScroll }) => {
  const [linkIsHover, setLinkIsHover] = useState('');
  const isHover = linkIsHover === 'scale';
  const pathRef = useRef();
  const path2Ref = useRef();

  ElevateIconAnimation(pathRef, path2Ref);
  return (
    <Link to="/about-us" onMouseOver={() => setLinkIsHover('scale')} onMouseLeave={() => setLinkIsHover('')}>
      <div className="relative h-[303px] sm:h-[359px] xl:h-[320px]">
        {/* Box Shadow */}
        <div
          className={clsx(
            'absolute left-4 top-6 bg-brand-other-main bg-opacity-[0.26] blur-[80px]',
            'transition-all duration-700',
            isHover ? 'h-[272px] w-[236px]' : 'h-[0px] w-[0px]'
          )}></div>

        {/* Background */}
        <div className={clsx('absolute left-0 top-0 h-full w-full overflow-hidden border border-black-30 bg-white')}>
          <div
            className={clsx(
              'relative h-[200%] w-[200%] bg-brand-other-main',
              'left-[-50%] origin-center transform rounded-[50%] duration-[350ms]',
              'transition-all ease-out',
              isHover ? 'translate-y-[-7.5%] scale-100' : 'translate-y-0 scale-0'
            )}></div>
        </div>

        {/* Content */}
        <div
          className={clsx(
            'relative flex min-h-[303px] flex-col justify-between px-6 py-6 sm:min-h-[359px] xl:min-h-[320px]',
            'transform duration-500',
            isHover ? 'translate-y-[-2.5%]' : 'translate-y-0'
          )}>
          <div>
            <div
              className={clsx(
                ' mx-auto mb-8 flex h-16 w-16 items-center justify-center bg-black-10 sm:mx-0',
                isHover ? 'bg-opacity-10' : 'bg-opacity-100'
              )}>
              <div id="elevate-icon" className={clsx('relative h-9 w-9 overflow-hidden transition-all', isHover ? 'text-white' : 'text-black-100')}>
                <svg
                  ref={path2Ref}
                  viewBox="0 0 36 36"
                  fill="none"
                  className="absolute"
                  width="100%"
                  height="100%"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M25.8 21C26.2155 21 26.4232 21 26.5916 21.0923C26.7305 21.1684 26.8598 21.3113 26.9217 21.4571C26.9967 21.6338 26.9778 21.8219 26.9401 22.1981C26.7444 24.1481 26.0732 26.0272 24.9776 27.6669C23.6591 29.6402 21.7849 31.1783 19.5922 32.0866C17.3995 32.9948 14.9867 33.2325 12.6589 32.7694C10.3311 32.3064 8.19295 31.1635 6.51472 29.4853C4.83649 27.8071 3.6936 25.6689 3.23058 23.3411C2.76756 21.0133 3.0052 18.6005 3.91345 16.4078C4.8217 14.2151 6.35977 12.341 8.33316 11.0224C9.97285 9.92677 11.8519 9.25562 13.802 9.05996C14.1781 9.02222 14.3662 9.00335 14.5429 9.07834C14.6887 9.14019 14.8316 9.26948 14.9077 9.40839C15 9.57677 15 9.78452 15 10.2V19.8C15 20.2201 15 20.4301 15.0818 20.5905C15.1537 20.7316 15.2684 20.8464 15.4095 20.9183C15.5699 21 15.78 21 16.2 21H25.8Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg ref={pathRef} viewBox="0 0 36 36" className="absolute" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21 4.20001C21 3.78451 21 3.57675 21.0923 3.40837C21.1684 3.26947 21.3113 3.14018 21.4571 3.07832C21.6338 3.00333 21.8219 3.0222 22.198 3.05993C24.9407 3.33503 27.519 4.54846 29.4853 6.51473C31.4516 8.48099 32.665 11.0594 32.9401 13.802C32.9778 14.1781 32.9967 14.3662 32.9217 14.5429C32.8598 14.6887 32.7305 14.8316 32.5916 14.9078C32.4233 15 32.2155 15 31.8 15L22.2 15C21.78 15 21.5699 15 21.4095 14.9183C21.2684 14.8464 21.1537 14.7316 21.0818 14.5905C21 14.4301 21 14.2201 21 13.8V4.20001Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <h2
              className={clsx(
                ' text-center text-head-six font-medium   sm:mb-[72px] sm:text-left sm:text-head-five',
                isHover ? 'text-neutral-10' : 'text-black-100'
              )}>
              Elevate your <br /> business
            </h2>
          </div>

          <button
            className={clsx('flex items-center self-center text-small  font-medium sm:self-start', isHover ? 'text-neutral-10' : 'text-neutral-90')}>
            View More
            <svg className="ml-1 h-6 w-6" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.59766 7.30688L15.3205 7.30688L15.3205 13.0297"
                stroke={isHover ? 'white' : '#1E293B'}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.30664 15.3206L15.2404 7.38682"
                stroke={isHover ? 'white' : '#1E293B'}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </Link>
  );
};

export default Elevate;
