import gsap from 'gsap';
import { useEffect } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const ElevateIconAnimation = (pathRef, path2Ref) => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.fromTo(
      pathRef.current,
      {
        left: '-50px',
        bottom: '-50px',
        opacity: 1
      },
      {
        left: '0',
        bottom: '0',
        opacity: 1,
        delay: 2,
        duration: 1.3,
        ease: 'Power3.easeInOut',
        scrollTrigger: {
          trigger: '#elevate-icon'
        }
      }
    );
    gsap.fromTo(
      path2Ref.current,
      {
        right: '-50px',
        top: '-50px',
        opacity: 1
      },
      {
        right: '0',
        top: '0',
        opacity: 1,
        delay: 2.5,
        duration: 1.3,
        ease: 'Power3.easeInOut',
        scrollTrigger: {
          trigger: '#elevate-icon'
        }
      }
    );
  }, []);
};

export default ElevateIconAnimation;
