import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper';
import useSwiperRef from 'utils/useSwiperRef';
import ListTestimonial from 'constant/testimonial/ListTestimonial';
import Card from './Card';
import clsx from 'clsx';
import useResponsive from 'utils/useResponsive';
import ListTestimonialMobile from 'constant/testimonial/ListTestimonialMobile';

const Slider = () => {
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();
  const { xl } = useResponsive();
  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState(null);

  const toggleTestimonial = (testimonialIndex) => {
    if (activeTestimonialIndex === testimonialIndex) setActiveTestimonialIndex(null);
    else setActiveTestimonialIndex(testimonialIndex);
  };
  const arrayTestimonial = [0, 1, 3];
  console.log(activeTestimonialIndex);
  console.log('activeTestimonialIndex === 0', arrayTestimonial.includes(activeTestimonialIndex));

  return (
    <div className=" col-span-full " data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="900" data-aos-duration="1500">
      <div
        className={clsx(
          'relative  h-[370px] pb-[58px] transition-all  sm:h-[430px]  sm:pb-0  xl:h-[406px] xl:pb-0',
          activeTestimonialIndex === null && 'mb-[200px] sm:mb-[60px] xl:mb-[192px]',

          activeTestimonialIndex === 4 && 'mb-[420px] sm:mb-[420px] xl:mb-[312px]',
          activeTestimonialIndex === 2 && 'mb-[312px] sm:mb-[200px] xl:mb-[312px]',
          activeTestimonialIndex === 0 && 'mb-[200px] sm:mb-[190px] xl:mb-[312px]',
          activeTestimonialIndex === 1 && 'mb-[200px] sm:mb-[430px] xl:mb-[312px]',
          activeTestimonialIndex === 3 && 'mb-[200px] sm:mb-[160px] xl:mb-[312px]',
          activeTestimonialIndex === 5 && 'mb-[200px] sm:mb-[160px] xl:mb-[312px]'
        )}>
        <div
          className={clsx(
            'absolute -left-[24px] -right-6 top-[120px]  bg-brand-other-surface transition-all sm:-left-[56px]  sm:-right-[56px] sm:top-[120px] xl:top-[70px] ',
            activeTestimonialIndex === null && 'h-[446px] sm:h-[368px] xl:h-[432px]',
            activeTestimonialIndex === 4 && 'h-[686px] sm:h-[728px] xl:h-[552px]',
            activeTestimonialIndex === 2 && 'h-[518px] sm:h-[508px] xl:h-[552px]',
            activeTestimonialIndex === 0 && 'h-[446px] sm:h-[438px] xl:h-[552px]',
            activeTestimonialIndex === 1 && 'h-[446px] sm:h-[372px] xl:h-[552px]',
            activeTestimonialIndex === 3 && 'h-[446px] sm:h-[458px] xl:h-[552px]',
            activeTestimonialIndex === 5 && 'h-[446px] sm:h-[458px] xl:h-[552px]'
          )}></div>
        <Swiper
          id="testimonials-slider"
          className="w-full "
          // style={{
          //   overflow: 'visible'
          // }}
          onSlideChange={() => setActiveTestimonialIndex(null)}
          pagination={{
            el: '.testimonials-pagination',
            clickable: true,
            renderBullet: (index, className) => {
              return '<span class="' + className + '"></span>';
            }
          }}
          navigation={{ prevEl, nextEl, clickable: true }}
          spaceBetween={32}
          slidesPerView={1}
          centeredSlides={true}
          onSwiper={(swiper) => console.log(swiper)}
          keyboard={true}
          loop={true}
          // speed={1000}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true
            // pauseOnMouseEnter: true
          }}
          // autoplay={false}
          modules={[Autoplay, Keyboard, Navigation, Pagination]}
          breakpoints={{
            640: {
              slidesPerView: 2
            },
            1024: {
              slidesPerView: 3
            },
            1280: {
              slidesPerView: 3
            }
          }}>
          {ListTestimonial.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Card
                  star={item.star}
                  word={item.word}
                  name={item.name}
                  job={item.job}
                  onClick={() => toggleTestimonial(index)}
                  active={activeTestimonialIndex === index}
                />
              </SwiperSlide>
            );
          })}
          {/* {!xl &&
            ListTestimonialMobile.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Card star={item.star} word={item.word} name={item.name} job={item.job} />
                </SwiperSlide>
              );
            })} */}
        </Swiper>
        <button
          ref={prevElRef}
          className="absolute left-[-30px] top-[39%] z-[1] hidden h-[48px] w-[48px] items-center justify-center rounded-full bg-white shadow-slider-nav sm:flex xl:-left-[80px]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5 19L8.5 12L15.5 5" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <button
          ref={nextElRef}
          className="absolute right-[-30px] top-[39%] z-[1] hidden h-[48px] w-[48px] items-center justify-center rounded-full bg-white shadow-slider-nav sm:flex xl:-right-[80px]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 19L15.5 12L8.5 5" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <div className="testimonials-pagination relative mt-[42px] flex justify-center sm:mt-8 sm:hidden"></div>
      </div>
    </div>
  );
};

export default Slider;
