import axios from 'axios';

const clientRoot = axios.create({
  baseURL: 'https://loxo.co/api/industry-talent/',
  auth: {
    username: 'industry-talent_api',
    password: 'UCg4pLjV'
  }
});

export { clientRoot };
