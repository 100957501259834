import React from 'react';
import queryStore from 'store/store';
import Card from './Components/Card';

const Keyword = () => {
  const [queryKeyword, setQueryKeyword] = queryStore((state) => [state.queryKeyword, state.setQueryKeyword]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const keyword = formData.get('keyword');
    setQueryKeyword(keyword);
  };
  return (
    <Card>
      <h6 className="mb-4 text-medium font-medium text-black-100 sm:text-large">Keyword</h6>
      <form className="relative" onSubmit={handleSubmit}>
        <input
          className={
            ('h-[48px] rounded-none border border-black-30 bg-white focus:border-brand-primary-main focus:ring-0',
            'w-full max-w-[323px] py-[12px] pl-4 pr-12 text-small placeholder:text-neutral-50 xl:text-base')
          }
          placeholder="Company, Job Tittle ..."
          name="keyword"
        />
        <button
          type="submit"
          className="absolute right-1 top-1 flex h-[36px] w-[40px] items-center justify-center bg-brand-primary-main xl:right-[8px] xl:top-[6px]">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </form>
    </Card>
  );
};

export default Keyword;
