import React, { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const Line = ({ delayTime }) => {
  gsap.registerPlugin(ScrollTrigger);
  const pathRef = React.useRef(null);
  useEffect(() => {
    gsap.fromTo(
      pathRef.current,
      {
        width: 0
      },
      {
        width: '100%',
        duration: 1.5,
        delay: delayTime,
        scrollTrigger: {
          trigger: '#line-black'
        }
      }
    );
  }, []);
  return (
    <div className="absolute inset-0 z-[2] flex items-center">
      <div ref={pathRef} id="line-black" className="h-[2px] bg-brand-primary-main"></div>
    </div>
  );
};

export default Line;
